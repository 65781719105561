export const SET_USER = 'SET_USER';

export const SET_AUCTION_LIST = 'SET_AUCTION_LIST';
export const SET_RAFFLE_LIST = 'SET_RAFFLE_LIST';
export const SET_DEALS_LIST = 'SET_DEALS_LIST';


export const SET_PRODUCT_OFFER = 'SET_PRODUCT_OFFER';
export const SET_PURCHASE_PRODUCTS = 'SET_PURCHASE_PRODUCTS';
export const SET_PARTICIPANTS_PRODUCTS = 'SET_PARTICIPANTS_PRODUCTS';

export const SET_ACTIVE_NAVBAR_ELEMENT = 'SET_ACTIVE_NAVBAR_ELEMENT';

export const SET_STRIPLE_TOKEN = 'SET_STRIPLE_TOKEN';

export const BASE_URL = process.env.NODE_ENV === 'development' ? '' : 'https://pownowapp.com';
export const STRIPE_URL = process.env.NODE_ENV === 'development' ? 'https://staging-pn-stripe.herokuapp.com/' : 'https://staging-pn-stripe.herokuapp.com/';