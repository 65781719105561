import React from 'react';
import {BidLogo, Utils} from "../../components/BidComponents";

import './style.scss';

const types = {
  win: 'win',
  loss: 'loss'
};

class BidReceivedResult extends React.Component {
  getReceivedHead = () => {
    const {receivedType = types.win} = this.props;

    let result;

    switch (receivedType) {
      case types.win:
        result = 'you won!';
        break;
      case types.loss:
        result = 'sorry';
        break;
    }

    return result;
  };

  getReceivedSubHead = () => {
    const {receivedType = types.win, productOffer} = this.props;

    let result;

    if (receivedType === types.win) {
      switch (productOffer.type) {
        case 'auction':
          result = `Congrats! You won this auction! The winning bid was $${productOffer.bid}`;
          break;
        case 'deals':
          result = `You purchased this deal! Please see the host to redeem your item.`;
          break;
        case 'raffle':
          result = `Congrats! You won this raffle! Please see the host to redeem your item.`
      }
    } else if (receivedType === types.loss) {
      switch (productOffer.type) {
        case 'auction':
          result = `Unfortunately you did not win this auction. 
          The winning bid was $ ${parseInt(productOffer.bidMax)}`;
          break;
        case 'deals':
          result = `Unfortunately you did not win this auction.`;
          break;
        case 'raffle':
          result = `Unfortunately you did not win this raffle. Try again next time!`;
      }
    }

/*    switch (receivedType) {
      case types.win:
        result = `Congrats! You won this auction! 
                  ${
          productOffer.type === 'auction'
            ? 'The winning bid was $' + productOffer.bid
            : 'Please see the host to redeem your item.'
          }` ;
        break;
      case types.loss:
        result = `Unfortunately you did not win this auction. 
                  ${productOffer.type === "auction" ? `The winning bid was $ ${parseInt(productOffer.bid) + 1000}` : ''}
          `;
        break;
    }*/

    return result;
  };

  getReceivedInfo = () => {
    const {productOffer, product} = this.props;

 //console.log("BIDRECEIVED productOffer:",productOffer);
      //console.log("BIDRECEIVED product:",product);

    let result = `${productOffer.bid ? productOffer.bid : 1} purchased`;

    if (productOffer.type === 'auction')
      result = `your bid: $${productOffer.bid}`;

    return result;
  };

  getReceivedButtonName = () => {
    const {productOffer} = this.props;
    //console.log("BIDRECEIVEDRESULT", productOffer);

    let result = 'buy again';

    if (productOffer.type === 'auction')
      result = `bid again`;

    return result;
  };

  render() {
    const {productOffer, product, modalBidAgain, modalClose, receivedType = types.bid} = this.props;

    if (!productOffer) {
      return (<div></div>);
    }

    return (
      <div className="modal-wrapper">
        <div className="modal-content">
          <div className="bid-modal">
            <div className="bid-modal-topline">
              <div className="modal-close"
                   onClick={modalClose}
              >
                <i className="fas fa-times" />
              </div>
            </div>
            <div className="bid-modal-wrapper">
              <div className="bid-modal-head">
                {this.getReceivedHead()}
              </div>
              <div className="bid-modal-subhead">
                {this.getReceivedSubHead()}
              </div>
              <div className="bid-modal-content">
                <div className="bid-modal-cover">
                  <img src={Utils.getProductImage(productOffer.type, product)} alt=""/>
                </div>
                <div className="bid-modal-info">
                  <BidLogo
                    link={product.sellerImage}
                  />
                  <div className="bid-modal-title">
                    <h2 className="bid-card-title">{product.title}</h2>
                    <h3 className="bid-card-subtitle">{this.getReceivedInfo()}</h3>
                  </div>
                </div>
              </div>
              <div className="bid-modal-describe">
                <span>Expires in 60 min</span>
                <p>{product.description}
                    <br/><br/><br/><br/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BidReceivedResult;