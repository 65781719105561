import React from 'react';
import Routes from '../../routes';
import { withRouter } from 'react-router-dom'
import PageWrapper from '../../components/PageWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import CustomButton from '../../components/CustomButton';
import Copyright from '../../components/Copyright';
import './style.scss';

class Login extends React.Component {
  render() {
    const { history } = this.props;

    return (
      <PageWrapper className="login-page">
        <ContentWrapper>
            <img className="login-image" src="https://pownowapp.com/img/PNheader.svg" alt=""/>
            <CustomButton
              className="login-button"f
              onClick={() => {history.push(Routes.signUp)}}
              text="sign up"
            />
            <CustomButton
              className="login-button"
              onClick={() => {history.push(Routes.signIn)}}
              text="log in"
            />
          <Copyright />
        </ContentWrapper>
      </PageWrapper>
    )
  }
};

export default withRouter(Login);