import React from 'react';
import Logo from '../../assets/logo.png';

import './style.scss';

const PageHeadline = () => {
  return (
    <div className="page-headline">
      <img src={Logo} alt=""/>
    </div>
  )
};

export default PageHeadline;