import React from 'react';
import {connect} from 'react-redux'
import Purchases from './Purchases';

import {fetchPurchasesList} from '../../actions/products';
import {getPurchaseCountdown} from "../../utils";

class PurchasesContainer extends React.Component {
  render() {
    const {raffle, deals, auction, purchases, fetchPurchasesList} = this.props;

    return (
      <Purchases
        fetchPurchasesList={fetchPurchasesList}
        purchases={getRightPurchase(purchases)}
        raffle={raffle}
        deals={deals}
        auction={auction}
      />
    )
  }

  componentDidMount() {
    const {fetchPurchasesList} = this.props;

    fetchPurchasesList();
  }
}

const getRightPurchase = (purchases) => {
  let maxMins = 48 * 60;

  return purchases;

  //no time limit
    /*return purchases.filter(elem => {
    const expMins = getPurchaseCountdown(elem.data, maxMins);

    return expMins > 0;
  });*/
};

const mapStateToProps = ({products}) => {
  return {
    raffle: products.raffle,
    deals: products.deals,
    auction: products.auction,
    purchases: products.purchases
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPurchasesList: () => {dispatch(fetchPurchasesList())}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesContainer);