import React from 'react';
import './style.scss';

const FormWrapper = ({children, className}) => {
  return (
    <div className={`form-wrapper ${className ? className : ''}`}>
      {children}
    </div>
  );
};

export default FormWrapper;