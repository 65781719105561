import React from 'react';
import {BidLogo, Utils} from "../../components/BidComponents";
import CustomButton from '../../components/CustomButton';
import moment from 'moment/moment.js';
import './style.scss';
import app from "../../base";

class BidReceivedModal extends React.Component {


  getReceivedHead = () => {
    const {productOffer,product} = this.props;
    const {chargeAuctionWinPurchases} = this.props;

    let result = 'got it!';
    const expired = this.getExpiredTime();
   
     //console.log("Product phoneNumber", productOffer);
      //console.log("Raffle Winner", product);
 
   if (!expired) { switch(productOffer.type) {
      case 'auction':
        productOffer.charged = false;
        result = 'bid sent';
        break;
      case 'raffle':
        result = 'entry received';
        break;
      default:
        result = 'got it!';
    } } else {
    switch(productOffer.type) {
      case 'auction':
           //console.log("Product in BidReceivedModal", parseInt(product.currentBidValue));
           //console.log("ProductOffer in BidReceivedModal", productOffer.bidMax);
        result =  (productOffer.bidMax === parseInt(product.currentBidValue) && productOffer.bidMax >= parseInt(product.reserveBidValue) ) ? 'YOU WON!':'you did not win this auction.';

        //console.log("Product Charged?", product.state);
        //console.log("ProductOffer Charged?", productOffer.state);
        //let isCharged = false; 
        const productRef = app.database().ref(`Purchased_Products/Auction/${productOffer.id}`);
        productRef.once('value', (snapshot) => {
        if (snapshot.val()) {
          const lastKey = snapshot.node_.children_.root_.maxKey();
          console.log("Purchased products snapshot?", snapshot.val());
            const productItemRef = productRef.child(`/${lastKey}/state`);

            productItemRef.once('value', (snapshotState) => {
              console.log("ProductOffer Chargged1?", snapshotState.val());
              if(snapshotState.val() || snapshotState.val() ===""){
                   if(snapshotState.val() === "charged"){
                     // console.log("ProductOffer Chargged?", snapshotState.val());
                      return result;
                    } else if(result === "YOU WON!") {
                            //console.log ("isCharged", isCharged);
                            console.log("Initiate Charge Auction WIN", productOffer.id);
                             console.log("PROPS", this.props);
                            chargeAuctionWinPurchases(productOffer.id);
                        
                    } 
              } 
            });

           
          }
        });

  

           //else if(result === "YOU WON!" && !productOffer.charged && productOffer.walletPay){
    
        //}
        break;
      case 'raffle':
       // console.log("Product in BidReceivedModal", product.raffleWinner);
       // console.log("ProductOffer in BidReceivedModal", productOffer.phoneNumber);
        result = productOffer.phoneNumber === product.raffleWinner ? 'YOU WON!':'Try again next time!';
        break;

      case 'deals':
        result = 'Got it!'
        break;

    
      default:
        result = 'Try Again!';
    } }

    return result;
  };

  getReceivedSubHead = () => {
    const {productOffer} = this.props;
    const expired = this.getExpiredTime();
    let result = 'We got your bid. You\'ll be notified about the results of the auction shortly!';

    if (!expired) { 
        switch (productOffer.type) {
          case 'raffle':
            result = '';
            break;
          case 'deals':
            result = 'Thank you for your purchase. Please see the host to redeem your item!';
            break;
          default:
            result = 'We got your bid. You\'ll be notified about the results of the auction shortly!';
        }
    }else{
        switch (productOffer.type) {
          case 'raffle':
            result = '';
            break;
          case 'deals':
            result = 'Thank you for your purchase. Please see the host to redeem your item!';
            break;
          default:
            result = 'We\'ve received your bid!';
         }
  }
    return result;
  };

  getExpiredTime = () => {
    const {product} = this.props;
    const endTimeMoment = moment(product.endTime, 'YYYY-MM-DD HH:mm:ss');
    const currMoment = moment().format('YYYY-MM-DD HH:mm:ss');
 
    //console.log("PRODUCT ENDTIME:",product.endTime);
    // console.log("Moment CurrTime:",currMoment);
    let result = endTimeMoment.isAfter(moment(currMoment ,'YYYY-MM-DD HH:mm:ss')) ? false : true

    return result;
  };

  getReceivedInfo = () => {
    const {productOffer, product} = this.props;

     //console.log("BIDRECEIVEDINDEX productOffer:",productOffer);
     // console.log("BIDRECEIVEDINDEX product:",product);

    let result = `${productOffer.bid ? productOffer.bid : 1} purchased`;

    if (productOffer.type === 'auction')
      result = `your bid: $${productOffer.bid}`;

    return result;
  };

  getReceivedButtonName = () => {
    const {productOffer} = this.props;
    const expired = this.getExpiredTime();

    let result = 'buy again';

    if (productOffer.type === 'auction')
      result = `bid again`;

    if (expired)
      result = `Ended`;

    return result;
  };

  render() {
    const {productOffer, product, modalBidAgain, modalClose} = this.props;

    if (!productOffer) {
      return (<div></div>);
    }

    return (
      <div className="modal-wrapper">
        <div className="modal-content">
          <div className="bid-modal">
            <div className="bid-modal-topline">
              <div className="modal-close"
                   onClick={modalClose}
              >
                <i className="fas fa-times" />
              </div>
            </div>
            <div className="bid-modal-wrapper">
              <div className="bid-modal-head">
                {this.getReceivedHead()}
              </div>
              <div className="bid-modal-subhead">
                {this.getReceivedSubHead()}
              </div>
              <div className="bid-modal-content">
                <div className="bid-modal-cover">
                  <img src={Utils.getProductImage(productOffer.type, product)} alt=""/>
                </div>
                <div className="bid-modal-info">
                  <BidLogo
                    link={product.sellerImage}
                  />
                  <div className="bid-modal-title">
                    <h2 className="bid-card-title">{product.title}</h2>
                    <h3 className="bid-card-subtitle">{this.getReceivedInfo()}</h3>
                  </div>
                </div>
              </div>
              <div className="bid-modal-describe">
                <span>Expires in 60 min</span>
                <p>{product.description}
                  <br/><br/><br/><br/>
                </p>
              </div>
                <div className="bid-modal-control">
                  <CustomButton
                    disabled = {this.getExpiredTime()}
                    onClick={modalBidAgain}
                    className="bid-modal-button"
                    text={this.getReceivedButtonName()}
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BidReceivedModal;