const getProductBadge = (type, product) => {
  let result;

  if (type === 'auction') {
    result = `BID: $${product.currentBidValue}`;
  } else {
    result = `$${product.price}`;
  }

  return result;
};

const getProductInfo = (type, product) => {
  let result;

  switch (type) {
    case 'auction':
     // result = `Min:$${product.minBidValue}`;
     result = product.silent ? `Silent` : ``;
      break;

    case 'raffle':
      result = product.silent ? `Anonymous` : ``;;
      break;

    case 'deals':
    default:
      result = `# Avail: ${product.limitQuantity}`;
  }

  return result;

};

const getProductImage = (type, product) => {
  let result;

  switch (type) {
    case 'auction':
      result = product.bidImage;
      break;

    case 'raffle':
      result = product.raffleImage;
      break;

    case 'deals':
    default:
      result = product.dealImage;
  }

  return result;
};

export default {
  getProductBadge,
  getProductImage,
  getProductInfo
}