import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Main from "./Main";
import {
  fetchRaffleList,
  fetchDealsList,
  fetchAuctionList, fetchPurchasesList
} from "../../actions/products";
import { getRightProducts } from '../../utils';



class MainContainer extends Component {
/*  handleSignOut = async event => {
    event.preventDefault();
    try {
      await app
        .auth()
        .signOut();
    } catch (error) {
      alert(error);
    }
  };*/



 constructor(props) {
    super(props);
  
    this.state = {
      intervalId: 100
    };
  }

 
  getRafflePage = () => {
    const {raffle, fetchRaffleList} = this.props;
   console.log('getRaffle Interval:',this.intervalId);
      clearInterval(this.intervalId);
      this.intervalId = undefined;
 
      
    return (
      <Main
        type={'raffle'}
        products={raffle}
        fetchList={fetchRaffleList}
      />
    );
  };

  getAuctionPage = () => {
    const {auction, fetchAuctionList} = this.props;
    console.log('getAuctionPage Interval:',this.intervalId);
    this.startAuctionUpdating();
     /*const intervalId  = setInterval(() => {
             this.updateProducts('auction');

      }, 10000);

      //this.setState( {intervalId});
      /*if(this.interval == null) {
           this.interval = setInterval(() => {
               
               this.updateProducts('auction');

        }, 5000);
      }*/
    return (
      <Main
        type={'auction'}
        products={auction}
        fetchList={fetchAuctionList}
      />
    );
  };

  getDealsPage = () => {
    const {deals, fetchDealsList} = this.props;
  console.log('getDealsPage Interval:',this.intervalId);
          clearInterval(this.intervalId);
          this.intervalId = undefined;
 
    return (
      <Main
        type={'deals'}
        products={deals.filter(elem => elem.limitQuantity > 0)}
        fetchList={fetchDealsList}
      />
    );
  };

  startAuctionUpdating = () => {

    if(!this.intervalId){
    this.intervalId  = setInterval(() => {
             console.log('startAuctionUpdating Interval:',this.intervalId);
             this.updateProducts('auction');

      }, 5000);
    }

  }

  render() {
    const { navBarActive } = this.props;

    if (navBarActive === 'auction') {
      return this.getAuctionPage();
    }

    let result;

    switch (navBarActive) {
      case 'raffle':
        result = this.getRafflePage();
        break;

      case 'deals':
        result = this.getDealsPage();
        break;

      default: {
        result = this.getAuctionPage();
      }
    }

    return result;
  }

 updateProducts(navBarActive) {
    const {fetchAuctionList, fetchRaffleList, fetchDealsList} = this.props;

    switch(navBarActive) {
      case 'raffle':
        fetchRaffleList();
        break;

      case 'deals':
        fetchDealsList();
        break;

      case 'auction':
      default:
        fetchAuctionList()
    }

  }

  componentWillReceiveProps(nextProps) {
    const {navBarActive} = this.props;


    if (nextProps.navBarActive !== navBarActive) {
      this.updateProducts(nextProps.navBarActive);
    }

 

   /* if(navBarActive ===  'auction' && nextProps.navBarActive !== 'auction'){
      clearInterval(this.interval);
    }*/

  }

  /*componentDidMount() {
    const {navBarActive, fetchPurchasesList} = this.props;

    this.updateProducts(navBarActive);

    if (navBarActive === 'auction'){
      //setInterval( this.updateProducts(navBarActive), 3000);
      setInterval(() => {
            this.updateProducts(navBarActive);
        }, 1000);
    }
    fetchPurchasesList();
  }*/

  componentDidMount() {
     const {navBarActive, fetchPurchasesList} = this.props;
    
   

    if (this.intervalId) {
      console.log('ComponenetDidMount PRESETUP interval value:',this.intervalId);
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
   
    this.updateProducts(navBarActive);
  

     if (navBarActive === 'auction'){
      this.startAuctionUpdating();
       console.log('ComponenetDidMount interval value started:',this.intervalId);

    }

    fetchPurchasesList();
  }


  componentWillUnmount() {
    clearInterval(this.intervalId)
  }


}

const mapStateToProps = ({products, navBar}) => {
  return {
    raffle: getRightProducts(products.raffle),
    deals: getRightProducts(products.deals),
    auction: getRightProducts(products.auction),
    navBarActive: navBar.elements.filter(elem => !!elem.active)[0].name
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRaffleList: (...props) => {dispatch(fetchRaffleList())},
    fetchDealsList: (...props) => {dispatch(fetchDealsList())},
    fetchAuctionList: (...props) => {dispatch(fetchAuctionList())},
    fetchPurchasesList: (...props) => {dispatch(fetchPurchasesList())}
    
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));