import { SET_ACTIVE_NAVBAR_ELEMENT } from "../actions/const";

const initialState = {
  elements: [
    {
      name: 'raffle',
      active: true
    },
    {
      name: 'auction'
    },
    {
      name: 'deals'
    }
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_NAVBAR_ELEMENT:
      return {...state, elements: state.elements.map(elem => {
          return elem.name === action.payload ? {...elem, active: true} : {...elem, active: false}
        })};
    default:
      return state;
  }
};
