import React from 'react';
import {getPurchaseCountdown} from '../../utils';

class PurchaseExpireTimer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expireMins: 0
    };

    this.maxMins = 48 * 60;
  }

  getValidStr = (string) =>{

    return `${string}`.length === 1 ? `0${string}` : string;
  };

   getTimeBought(){
    const{purchaseData} = this.props;

    let result = purchaseData;
    let hours = `${purchaseData}`.substring(11,13);
    let mins = `${purchaseData}`.substring(14,16);

    let hoursInt = parseInt(hours);
    let amPM = hoursInt > 12 ? ' PM' : ' AM'
    let hourTime = hoursInt > 12 ? this.getValidStr(Math.round(hoursInt - 12)) : this.getValidStr(Math.round(hoursInt))
    hourTime = hourTime == '00' ? '12' : hourTime
    

    return 'Purchased: ' + hourTime + ':' + mins + amPM ;

  }

   getDateBought(){
    const{purchaseData} = this.props;

    let result = purchaseData;

    return `${purchaseData}`.substring(0,10);

  }

  componentDidMount() {
    const {purchaseData, fetchPurchasesList} = this.props;

    console.log("COMPONENT PURCHASE DATA:", purchaseData);

    const startExp = getPurchaseCountdown(purchaseData, this.maxMins);

    this.setState({
      expireMins: startExp
    }, () => {
      if (startExp <= 60) {
        this.interval = setInterval(() => {

          const startExp = getPurchaseCountdown(purchaseData, this.maxMins);

          if (startExp === 0) {
            fetchPurchasesList();
          }

          this.setState({
            expireMins: startExp
          })
        }, 1000*60);
      }
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  render() {
    const { expireMins } = this.state;
    const {purchaseData } = this.props;

    return (
      <span className="purchases-card-timer">
        {
          //expireMins === 0 ? 'Expired' : `Exp ${expireMins > 60 ? this.getValidStr(Math.round(expireMins/60)) + ' hrs' : this.getValidStr(Math.round(expireMins)) + ' mins'}`
          this.getTimeBought()
        }
      </span>

    )
  }


  
}

export default PurchaseExpireTimer;