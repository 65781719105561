import React from 'react';
import BackButton from '../BackButton';

import './style.scss';

const PageHeader = ({header}) => {
  return (
    <div className="page-header">
      <div className="page-header-nav">
        <BackButton/>
      </div>
      <div className="page-header-content">
        <h1>{header}</h1>
      </div>
    </div>
  )
};

export default PageHeader;