import React from 'react';
import FormWrapper from '../../components/FormWrapper';
import CustomButton from '../../components/CustomButton';
import StripleCustomInput from '../../components/StripleCustomInput';
import StripleNewButton from '../../components/StripleNewButton';
import PaymentRequestForm from '../../components/PaymentRequestForm';
import history from '../../history';
import Routes from '../../routes';
import { injectStripe } from 'react-stripe-elements';

import {connect} from 'react-redux';
import {payProduct, saveStripleToken} from "../../actions";

import './style.scss';

class StripleSavedForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formCard: null,
      isLoading: false
    }
  }

  submit = async (event) => {
    const {formCard, isLoading} = this.state;
    const {stripeCards, saveTokenToProduct, payProduct, price, productOffer} = this.props;

    event.preventDefault();

    const source = stripeCards.filter(elem => elem.id === formCard)[0];

     let quantity = 1;

        if (productOffer.type !== 'auction'){
            quantity = productOffer.bid;
        }

    //let {token} = await this.props.stripe.createToken(tok.card.id);

    //console.log("TOKLOADING", source);
    //console.log("SAVEDFORM PRODUCTOFFERBID", productOffer.bid);

      if(this.state.isLoading){
            return;
        }
        console.log("GETS HERE Yo");
        this.setState({
            isLoading: true
        })
    payProduct(parseInt((parseFloat(price) + parseFloat(price * 0.05)).toFixed(2) * quantity * 100), source, productOffer.type, false, true, productOffer.id, quantity);

    saveTokenToProduct(source);

    //history.push(Routes.bidReceived);
  };

  onCardChange = (card) => {

    const {formCard} = this.state;

    if (formCard === card) {
      this.setState({formCard: null})
    } else {
      this.setState({formCard: card})
    }

  };

  applePayOnClick = () => {
        const {isLoading} = this.state;
         this.setState({
            isLoading: true
        })
    };

  render() {
    const {formCard, isLoading} = this.state;
    const {stripeCards, setNewCard} = this.props;
    const {price, productOffer} = this.props;

    //console.log('CARDS', stripeCards);

  
    console.log('SAVEDFORM PRICE', price);
     console.log('SAVEDFORM productoffer TYPE', productOffer.type);
    console.log('SAVEDFORM productOffer bid', productOffer.bid);
    console.log('SAVEDFORM productOffer ID', productOffer.id);

    if (!stripeCards || !stripeCards.length) {
      return <div></div>
    }

    return (
      <FormWrapper className="striple-saved">
        <form onSubmit={this.submit}>
          <div className="form-head">
            <p>Enter your payment information</p>
          </div>

          <div className="form-content">
            {
              stripeCards.map((elem) => {
                return <StripleCustomInput
                  key={elem.id}
                  token={elem}
                  isActive={formCard === elem.id}
                  onChange={this.onCardChange}
                />
              })
            }
            <StripleNewButton
              setNewCard={setNewCard}
            />
          </div>


          <div className="form-control">
                <CustomButton
                  type="submit"
                  disabled={!formCard || isLoading}
                  text={isLoading ? 'Processing... ' :'purchase'}
                />

          </div>

          <div className="form-control">
                          <PaymentRequestForm
                           onClick = {this.applePayOnClick}
                           amount = {price}
                           type = {productOffer.type}
                           id = {productOffer.id}
                           quantity = {productOffer.type === 'auction' ? 1 : productOffer.bid}
                          />             
          </div> 


        </form>
      </FormWrapper>
    )
  }
}

const mapStateToProps = ({login: {stripleToken}, products: {productOffer}}) => {
    return {
        stripleToken,
        productOffer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveStripleToken: (...props) => {dispatch(saveStripleToken(...props))},
        payProduct: (...props) => {dispatch(payProduct(...props))},
        PaymentRequestForm: (...props) => {dispatch(PaymentRequestForm(...props))}
    }
};

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(StripleSavedForm));