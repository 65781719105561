import React from 'react';
import { API_KEY } from '../../stripleConfig';
import StripleCustomForm from '../../components/StripleCustomForm';
import StripleSavedForm from '../../components/StripleSavedForm';
import {Elements, StripeProvider} from 'react-stripe-elements';
import StripleProductCard from '../../components/StripleProductCard';
import PageHeadline from '../../components/PageHeadline';

import './style.scss';

class StripleCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      savedForm: true
    }
  }

  setNewCard = () => {
    this.setState({savedForm: false});
  };

  getForm = (price, type, id) => {
    const {stripeCards, saveToken, saveTokenToProduct, payProduct} = this.props;
    const {savedForm} = this.state;

    if (!stripeCards) {
      return (
        <Elements>
          <StripleCustomForm
            price={price}
            type={type}
            id={id}
            saveToken={saveToken}
            saveTokenToProduct={saveTokenToProduct}
          />
        </Elements>
      )
    } else {
      if (savedForm) {
        return (
        <Elements>
          <StripleSavedForm
            type={type}
            stripeCards={stripeCards}
            setNewCard={this.setNewCard}
            payProduct={payProduct}
            price={price}
            saveTokenToProduct={saveTokenToProduct}
          />
          </Elements>
        )
      } else {
        return (
          <Elements>
            <StripleCustomForm
              type={type}
              price={price}
              id={id}
              saveToken={saveToken}
              saveTokenToProduct={saveTokenToProduct}
            />
          </Elements>
        )
      }
    }
  };

  render() {
    const {product, productOffer} = this.props;

    let price = productOffer.type === "auction" ? productOffer.bid : product.price;
    price = parseInt(price) + parseInt(price*0.05);

    console.log('dd', API_KEY);

    return (
      <StripeProvider apiKey={API_KEY} >
        <div className="striple-page">
          <PageHeadline />
          <div className="page-container">
            <StripleProductCard
              product={product}
              productOffer={productOffer}
            />
            {
              this.getForm(price, product.type, productOffer.id)
            }
          </div>
        </div>
      </StripeProvider>
    )
  }
}

export default StripleCheckout;