import React from 'react';
import { ReactComponent as PhoneLogo } from './Icons/phone1.svg';
import { ReactComponent as PhoneErrorLogo } from './Icons/phone2.svg';
import { ReactComponent as NameLogo } from './Icons/username1.svg';
import { ReactComponent as NameErrorLogo } from './Icons/username2.svg';
import { ReactComponent as CodeLogo } from './Icons/password1.svg';
import { ReactComponent as CodeErrorLogo } from './Icons/password2.svg';

import './style.scss';

const getInputIcon = (icon, error, touched) => {
  let IconComponent = PhoneLogo;

  if (icon === 'name') {
    IconComponent = error && touched ? NameErrorLogo : NameLogo;
  } else if (icon === 'code') {
    IconComponent = error && touched ? CodeErrorLogo :CodeLogo;
  } else {
    IconComponent = error && touched ? PhoneErrorLogo : PhoneLogo;
  }

  return IconComponent;
};

const CustomInput = ({icon, meta, input}) => {

  let error = meta.error;
  let touched = meta.touched;



  let IconComponent = getInputIcon(icon, error, touched);

  return (
    <div className={`custom-input ${icon ? 'input-with-icon' : ''} ${error && touched ? "input-error" : ''}`}>
      <label>
        <div className="custom-input-icon">
          <IconComponent />
        </div>

        <input
          {...input}
        />

      </label>
    </div>
  )
};

export default CustomInput;