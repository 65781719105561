import {
  SET_RAFFLE_LIST,
  SET_DEALS_LIST,
  SET_AUCTION_LIST,
  SET_PRODUCT_OFFER,
  SET_PURCHASE_PRODUCTS,
  SET_PARTICIPANTS_PRODUCTS
} from "../actions/const";

const initialState = {
  raffle: [],
  deals: [],
  auction: [],
  purchases: [],
  participants:[],
  productOffer: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RAFFLE_LIST:
      return {...state, raffle: action.payload};
    case SET_DEALS_LIST:
      return {...state, deals: action.payload};
    case SET_AUCTION_LIST:
      return {...state, auction: action.payload};
    case SET_PRODUCT_OFFER:
      return {...state, productOffer: action.payload.id ? {...action.payload} : null};
    case SET_PURCHASE_PRODUCTS:
      return {...state, purchases: action.payload};
    case SET_PARTICIPANTS_PRODUCTS:
      return {...state, participants: action.payload};
    default:
      return state;
  }
};
