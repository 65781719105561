import React from 'react';

const BidLogo = ({link}) => {
  return (
    <div className="bid-card-logo">
      <img src={link} alt=""/>
    </div>
  )
};

export default BidLogo;