import React from 'react';
import {BidLogo} from "../BidComponents";
import { NavLink } from 'react-router-dom';
import Routes from '../../routes';

import './style.scss';

const getImage = (product, type) => {
  let result;

  switch(type) {
    case "raffle":
      result = product.raffleImage;
      break;
    case "deals":
      result = product.dealImage;
      break;
    default:
      result = product.bidImage;
  }

  return result;
};

const StripleProductCard = ({product, productOffer}) => {

  let price = productOffer.type === "auction" ? productOffer.bid : product.price;

  let quantity = productOffer.type === "auction" ? 1 : productOffer.bid;

  let image = getImage(product, productOffer.type);

  return (
    <div className="striple-product-card">
      <div className="card-wrapper">
        <div className="card-column">
          <BidLogo
            link={image}
          />
        </div>
        <div className="card-column column-info">
          <div className="card-row">
            <span className="product-title">{product.title}</span>
            <span>{`$${parseFloat(price).toFixed(2)}`}</span>
          </div>
          { productOffer.type !== 'auction' &&
           <div className="card-row">
            <span className="product-quantity">{'Quantity'}</span>
            <span>x{quantity}</span>
          </div>
          }
          <div className="card-row">
            <span>Transaction fee (5.00%)</span>
            <span>{`$${parseFloat(price * quantity * 0.05).toFixed(2)}`}</span>
          </div>
          <div className="card-row card-bold">
            <span>Total</span>
            <span>{`$${parseFloat(parseInt(price * quantity) + (price * quantity * 0.05)).toFixed(2)}`}</span>
          </div>
          { productOffer.type === 'auction' &&
           <div className="card-row">
            <span className="auction-advisory">{'Unless you have won the auction, you will not be charged and this only reserves your bid placement'}</span>
          </div>
          }
          <div className="card-row card-terms">
            <span>Read our <NavLink to={Routes.terms}>Terms of Services</NavLink> and <NavLink to={Routes.privacy}>Privacy Policy</NavLink></span>
          </div>
        </div>
      </div>
    </div>
  )
};

export default StripleProductCard;

