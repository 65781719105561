import React from 'react';
import {withRouter} from 'react-router-dom';
import MenuBar from '../MenuBar';
import NavBar from '../NavBar';

class AppLayout extends React.Component {
  isNavBarPage = () => {
    const {location:{pathname}} = this.props;

    return pathname.includes('/main');
  };

  render() {
    const {children} = this.props;

    return (
      <div className="app-wrapper">
        <MenuBar />
        {
          this.isNavBarPage() && <NavBar />
        }
        {children}
      </div>
    )
  }
}

export default withRouter(AppLayout);