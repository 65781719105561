import React, { Component } from 'react';
import {compose} from 'redux';
import { connect } from 'react-redux';
import { setUser, setStripleToken } from './actions';
import Routes from './routes';
import {Switch, withRouter, Route} from 'react-router-dom';
import PageWrapper from './components/PageWrapper';
import ContentWrapper from './components/ContentWrapper';
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Login from './pages/Login';
import Main from './pages/Main';
import ProductPage from './pages/ProductPage';
import BidReceived from './pages/BidReceived';
import ProductReceived from './pages/ProductReceived';
import Purchases from './pages/Purchases';
import Terms from './pages/Terms';
import Help from './pages/Help';
import Privacy from './pages/Privacy';
import PrivateRouter from './PrivateRouter';
import LoginRoutes from './LoginRouter';
import VerifyCode from './pages/VerifyCode';
import StripleCheckout from './pages/StripleCheckout';
import AppLayout from './components/AppLayout';
import app from './base';

class App extends Component {
  componentDidMount() {
    const { setUser, history, location, setStripleToken} = this.props;

    app.auth().onAuthStateChanged(user => {

      setUser(user);

      if (user) {
        const userRef = app.database().ref(`/users/${user.phoneNumber}`);

        const stripleTokenRef = userRef.child(`stripe/cards`);

        stripleTokenRef.once('value', (snapshot) => {
          if (snapshot.val()) {
            setStripleToken(snapshot.val());
          }
        });
      }

      if (user && (
          location.pathname === Routes.login
          || location.pathname === Routes.signUp
          || location.pathname === Routes.signIn)){
        history.push(Routes.main);
      }
    });
  }

  render() {
    const {user, isLoaded} = this.props;

    if (!isLoaded) {
      return (
        <PageWrapper>
          <ContentWrapper>
            Loading...
          </ContentWrapper>
        </PageWrapper>
      );
    }

    return (
      <div>
        <Switch>
          <LoginRoutes authenticated={!!user} exact path={Routes.login} component={Login}/>
          <LoginRoutes authenticated={!!user} path={Routes.signIn} component={SignIn}/>
          <LoginRoutes authenticated={!!user} path={Routes.signUp} component={SignUp}/>
          <LoginRoutes authenticated={!!user} path={Routes.verifyCode} component={VerifyCode}/>
          <Route path={Routes.terms} component={Terms}/>
          <Route path={Routes.privacy} component={Privacy}/>
          <Route path={Routes.help} component={Help}/>
          <PrivateRouter authenticated={!!user} path={`${Routes.stripleCheckout}`} component={StripleCheckout}/>
          <PrivateRouter authenticated={!!user} path={`${Routes.productPage}/:type/:id`} component={ProductPage}/>
          <PrivateRouter authenticated={!!user} path={`${Routes.bidReceived}`} component={BidReceived}/>
          <PrivateRouter authenticated={!!user} path={`${Routes.productReceivedPage}/:pageType/:type/:id`} component={ProductReceived}/>
          <PrivateRouter authenticated={!!user} path={`${Routes.purchases}`} component={Purchases}/>
          <AppLayout>
            <Switch>
              <PrivateRouter authenticated={!!user} path={`${Routes.main}`} component={Main}/>
            </Switch>
          </AppLayout>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({login}) => {
  return {
    isLoaded: login.isLoaded,
    user: login.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    setStripleToken: (token) => dispatch(setStripleToken(token))
  }
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
