import React from 'react';
import CustomCheckbox from '../CustomCheckbox';
import StripleSavedCard from '../StripleSavedCard';

import './style.scss';

class StripleCustomInput extends React.Component {
  render() {
    const {token, onChange, isActive} = this.props;

    return (
      <div
        className="striple-input"
      >
        <div className="checkbox">
          <CustomCheckbox
            value={isActive}
            input={{
              onChange: (event) => {onChange(token.id)}
            }}
          />
        </div>
        <div
          className="card"
        >
          <StripleSavedCard
            type="card"
            name={token.card.brand}
            card={`***${token.card.last4}`}
          />
        </div>
      </div>
    )
  }
}

export default StripleCustomInput;