import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import StripleCheckout from './StripleCheckout';
import Routes from '../../routes';
import {
  payProduct,
  saveStripleToken,
  saveStripleTokenToProduct,
  fetchSavedCards
} from "../../actions";

class StripleCheckoutContainer extends React.Component {
  saveToken = (token) => {
    const {saveStripleToken} = this.props;

    saveStripleToken(token);
  };

  saveTokenToProduct = (token) => {
    const {productOffer, saveStripleTokenToProduct} = this.props;

    saveStripleTokenToProduct(token, productOffer.type, productOffer.id);
  };

  render() {
    const { auction, raffle, deals, productOffer, stripeCards, payProduct} = this.props;

    let products;

    if (!productOffer || !productOffer.id) {
      return (<Redirect to={Routes.main} />)
    }

    switch (productOffer.type) {
      case 'raffle':
        products = raffle;
        break;

      case 'deals':
        products = deals;
        break;

      case 'auction':
      default:
        products = auction;
    }

    const product = products.filter(elem => {
      return elem.id === productOffer.id
    })[0];

    return (
      <StripleCheckout
        product={product}
        productOffer={productOffer}
        stripeCards={stripeCards}
        saveToken={this.saveToken}
        saveTokenToProduct={this.saveTokenToProduct}
        payProduct={payProduct}
      />
    )
  }

  componentDidMount() {
    const {fetchSavedCards} = this.props;

    fetchSavedCards();
  }
}

const mapStateToProps = ({products, login: {stripeCards}}) => {
  return {
    auction: products.auction,
    raffle: products.raffle,
    deals: products.deals,
    productOffer: products.productOffer,
    stripeCards
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSavedCards: (...props) => {dispatch(fetchSavedCards(...props))},
    saveStripleToken: (...props) => {dispatch(saveStripleToken(...props))},
    saveStripleTokenToProduct: (...props) => {dispatch(saveStripleTokenToProduct(...props))},
    payProduct: (...props) => {dispatch(payProduct(...props))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(StripleCheckoutContainer);