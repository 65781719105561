import React from 'react';
import {connect} from 'react-redux';
import history from '../../history';
import Routes from '../../routes';
import {payProduct, saveStripleToken} from "../../actions";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    PostalCodeElement,
    injectStripe,
    PaymentRequestButtonElement
} from 'react-stripe-elements';
import FormWrapper from '../../components/FormWrapper';
import CustomButton from '../../components/CustomButton';
import PaymentRequestForm from '../../components/PaymentRequestForm';
import CustomCheckbox from '../../components/CustomCheckbox';
import axios from 'axios';
import app from "../../base";
import firebase from 'firebase';
import './style.scss';


const inputStyle = {
    base: {
        color: "#000",
        fontSize: "1rem",
        lineHeight: "40px",
        padding: "0 0 0 5px"
    },
    invalid: {
        color: "#000"
    }
};

const isDevelopment = process.env.NODE_ENV === 'development';
const target = isDevelopment ? 'stripeDev' : 'stripe';


class StripleCustomForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: {
                cardNumber: true,
                cardExpiry: true,
                cardCvc: true,
                postalCode: true
            },
            save: false,
            formValid: false,
            isLoading: false
        }
    }

    submit = async (event) => {
        const {save, isLoading} = this.state;
        const {saveToken, saveTokenToProduct, payProduct, price, id, productOffer} = this.props;

        event.preventDefault();

        let quantity = 1;

        if (productOffer.type !== 'auction'){
            quantity = productOffer.bid;
        }

        //let {token} = await this.props.stripe.createToken();
       if(this.state.isLoading){
            return;
        }
        console.log("GETS HERE Yo");
        this.setState({
            isLoading: true
        })

          let {source} = await this.props.stripe.createSource({
              type: "card",
              owner: {
                  name: app.auth().currentUser.name,
                  email: `${app.auth().currentUser.phoneNumber}@pownowapp.com`,
              }
          });
          //console.log("token", source);

        //console.log("222", this.props);

/*        this.props.stripe.handleCardPayment()
            .then((result) => {
                console.log('Method', result);
            });*/
            console.log("Gets here123 OG SUBMIT");
            console.log("ID:" ,id);
            console.log("Type:" ,productOffer.type);
        /*this.setState({
            isLoading: true
        });

         await this.props.payProduct(parseInt(price * 100), source, type, save, false,id)
            .then(async response => {
              this.setState({
                isLoading: false
                 })
            });*/


        payProduct(parseInt((parseFloat(price) + parseFloat(price * 0.05)).toFixed(2) * quantity * 100), source, productOffer.type, save, false, id, quantity);

        //use new card
        //localStorage.token = JSON.stringify(token);

        saveTokenToProduct(source);

/*        if (save) {
            saveStripleToken(source);
        }*/

        //history.push(Routes.bidReceived);
        //}
    };

    submitNew = async (event) => {
        const {save, isLoading} = this.state;
        const {saveToken, saveTokenToProduct} = this.props;

        event.preventDefault();


        const usersRef = firebase.database().ref(`/users/${app.auth().currentUser.phoneNumber}/${target}`);
        //const userRef = usersRef.child('stripeCustId');
        const userRef = usersRef.child('customerID');
        var stripeCustomerId;
        var source;
        var isNewUser = true;

        userRef.once('value').then(async snapshot => {
            if (snapshot.val()) {
                stripeCustomerId = snapshot.val();
                isNewUser = false;
                console.log("Found stripe cust ID");
                console.log(stripeCustomerId);
            }
        });

        console.log("Gets here SUBMITNEW");
        this.setState({
            isLoading: true
        })

        await this.props.stripe.createSource({
            type: "card",
            owner: {
                name: app.auth().currentUser.name,
                email: `${app.auth().currentUser.phoneNumber}@pownowapp.com`,
            }
        }).then(async response => {
            source = response.source;
            var formData  = new FormData();
            formData.append("source", source.id);
            formData.append("phone", app.auth().currentUser.phoneNumber);
            if(!isNewUser){
                formData.append("customer_id",stripeCustomerId);
                console.log("Found stripe cust ID");
                console.log(stripeCustomerId);
            }

            await fetch(
                isNewUser ? "https://staging-pn-stripe.herokuapp.com/createCustomer" : "https://staging-pn-stripe.herokuapp.com/attachSource",
                {
                    mode: 'no-cors', // no-cors
                    method: "POST",
                    body: formData,
                    headers: {
                        "Content-type":"text/html",
                    }
                }
            ).then(async response => {
                console.log("Source:", response);
                console.log(response.text());
                console.log(source.id);

                //fetch CustomerId from DB
                const usersRef = firebase.database().ref(`/users/${app.auth().currentUser.phoneNumber}/${target}`);
                //const userRef = usersRef.child('stripeCustId');
                const userRef = usersRef.child('customerID');
                userRef.once('value').then((snapshot) => {
                    if (snapshot.val()) {
                        stripeCustomerId = snapshot.val();
                    } else {
                        //error;
                    }
                });

                //return response.text();
            })
                .then(async data=>{
                    console.log("Data", data);

                    var formDataCharge  = new FormData();
                    formDataCharge.append("amount", "5000");
                    formDataCharge.append("source", source.id);
                    formDataCharge.append("customer_id",stripeCustomerId);


                    await fetch(
                        "https://staging-pn-stripe.herokuapp.com/chargeCustomer",
                        {
                            mode: 'no-cors', // no-cors
                            method: "POST",
                            body: formDataCharge,
                            headers: {
                                "Content-type":"text/html",
                            }
                        }
                    )
                        .then(async response => {
                            console.log('responce', response);
                            //use new card
                            localStorage.source = JSON.stringify(source);

                            saveTokenToProduct(source);


                            if (save) {
                                saveToken(source);
                            }
                            return response.json();
                        })
                        .then(data => {
                            console.log('ffg2', data)
                        })
                        .catch(error => {
                            //Error
                        });

                })
                .catch(error => {
                    console.log('err1', error)
                });

        })
            .catch(
                error => console.log('err2', error));


        history.push(Routes.bidReceived);
    };

    stripleOnChange = ({elementType, complete}) => {
        const {error} = this.state;

        error[elementType] = !complete;

        this.setState({
            error
        }, this.validateForm)
    };

     applePayOnClick = () => {
        const {isLoading} = this.state;
         this.setState({
            isLoading: true
        })
    };

    /*purchaseLoading = () => {
        const {error} = this.state;

        if(this.state.isLoading){
            return;
        }
        console.log("GETS HERE Yo");
        this.setState({
            isLoading: true
        })

    };*/

    validateForm = () => {
        const {error, savedCard} = this.state;

        let result = true;

        if (!savedCard) {
            for (let key in error) {
                if (error[key]) {
                    result = false
                }
            }
        }

        this.setState({
            formValid: result
        })
    };

    render() {
        const {formValid, save, isLoading} = this.state;
        const {price, id, productOffer} = this.props;

       /* console.log('Custom FORM PRICE', price);
        console.log('Custom FORM id', id);
     console.log('Custom FORM productoffer TYPE', productOffer.type);
    console.log('Custom FORM productOffer bid', productOffer.bid);
    console.log('Custom FORM productOffer ID', productOffer.id);*/

        return (
            <FormWrapper>
                <form onSubmit={this.submit}>
                    <div className="form-head">
                        <p>Enter your payment information</p>
                    </div>

                    <div className="form-content">
                        <CardNumberElement
                            style={inputStyle}
                            onChange={this.stripleOnChange}
                        />

                        <div className="form-inline-fields">
                            <CardExpiryElement
                                style={inputStyle}
                                onChange={this.stripleOnChange}
                            />
                            <CardCVCElement
                                style={inputStyle}
                                onChange={this.stripleOnChange}
                            />
                           
                        </div>

                         <PostalCodeElement
                            style={inputStyle}
                            onChange={this.stripleOnChange}
                         />
                     

                        <CustomCheckbox
                            input={{
                                onChange: (event) => {this.setState({save: event.target.checked})}
                            }}
                            value={save}
                            placeholder="Save credit card information"
                        />
                    </div>

                    <div className="form-control">
                        <CustomButton
                            type="submit"
                            disabled={!formValid || isLoading}
                            text={isLoading ? 'Processing... ' :'purchase'}
                        />
                    </div>

                       <div className="form-control">
                          <PaymentRequestForm
                           onClick = {this.applePayOnClick}
                           amount = {price}
                           type = {productOffer.type}
                           id = {id}
                           quantity = {productOffer.type === 'auction' ? 1 : productOffer.bid}
                            

                          />
                    
                        </div> 
                </form>
            </FormWrapper>
        )
    }
}

const mapStateToProps = ({login: {stripleToken}, products: {productOffer}}) => {
    return {
        stripleToken,
        productOffer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveStripleToken: (...props) => {dispatch(saveStripleToken(...props))},
        payProduct: (...props) => {dispatch(payProduct(...props))},
        PaymentRequestForm: (...props) => {dispatch(PaymentRequestForm(...props))}
    }
};

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(StripleCustomForm));
