import React from 'react';

const BidCover = ({link}) => {
  return (
    <div className="bid-card-cover">
      <img src={link} alt=""/>
    </div>
  )
};

export default BidCover;