import React from 'react';
import BidReceivedModal from '../../components/BidReceivedModal/BidReceivedResult';

class ProductReceived extends React.Component {
  render() {
    const { product, productOffer , modalClose } = this.props;

    return (
      <div>
        <BidReceivedModal
          receivedType={product.state}
          productOffer={productOffer}
          product={product}
          modalClose={modalClose}
        />
      </div>
    )
  }
}

export default ProductReceived;