import {
  SET_USER,
  SET_STRIPLE_TOKEN
} from "../actions/const";

const initialState = {
  user: null,
  isLoaded: false,
  stripeCards: null
};

const getToken = (token) => {
  const result = [];

  for(let key in token) {
    if (token.hasOwnProperty(key)) {
      result.push(token[key]);
    }
  }

  return result;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {...state, user: action.payload, isLoaded: true};
    case SET_STRIPLE_TOKEN:
      return {...state, stripeCards: getToken(action.payload)};
    default:
      return state;
  }
};
