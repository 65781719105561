import React from 'react';
import Info from '../../components/Info';
import app from "../../base";

class TermsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ''
    }
  }

  render() {
    const {text} = this.state;

    return (
      <Info
        text={text}
      />
    )
  }

  componentDidMount() {
    const termsRef = app.database().ref(`/terms`);

    termsRef.once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({
          text: snapshot.val()
        })
      }
    });
  }
}

export default TermsContainer;