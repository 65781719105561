import {
  STRIPE_URL,
  SET_USER,
  SET_STRIPLE_TOKEN
} from './const';
import app from '../base';
import axios from 'axios';
import history from '../history';
import Routes from '../routes';
import {useEffect, useState} from "react";
import {setAuctionServerUpdate, setDealsServerUpdate, setRaffleServerUpdate} from '../actions/products';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosStipe = axios.create({
    baseURL: STRIPE_URL,
    timeout: 10000,
    headers: {'Content-type':'application/json'}
});

const isDevelopment = process.env.NODE_ENV === 'development';
const target = isDevelopment ? 'stripeDev' : 'stripe';

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user
  }
};

 /*axiosStipe.interceptors.response.use(null, error => {
    const expectedError = 
    error.response &&
    error.response >= 400 &&
    error.response < 500;

    if(!expectedError){
        //console.log('Logging the error', error);
        /*toast.error("An unexpected error occured!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            })*/
  /*  }

    return Promise.reject(error)
});*/

export const payProduct = (amount, source, type, save = false, isSavedCard = false, id, quantity) => {
  return (dispatch, getState) => {

    const state = getState();

    const {login} = state;

    const userRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);

    const isCharged = false;

    sessionStorage[`auctionWin-${id}`] = sessionStorage[`auctionWin-${id}`] == null ? 0 : sessionStorage[`auctionWin-${id}`];
    //sessionStorage 1 = needs to be charged, 2 = charged, 0 = default state

    /*const productRef = app.database().ref(`Purchased_Products/Auction/${id}`);
        productRef.once('value', (snapshot) => {
        if (snapshot.val()) {
          const lastKey = snapshot.node_.children_.root_.maxKey();
         isCharged = productRef.child(`/${lastKey}/charged`) ? true : false; 
    
   

    console.log("IDs: ", id);
    //if(type === "auction" && 

  
    if(type === "auction" && sessionStorage[`auctionWin-${id}`] == 2 && isCharged){
      console.log("SUCCESS 100", sessionStorage[`auctionWin-${id}`]);
    } else {
      console.log("SUCCESS 2",sessionStorage[`auctionWin-${id}`]);
    }
          }
  });*/
    if(type === "auction"){
      console.log("Dispatching setAuctionServerUpdate: ", id);
       console.log("Dispatching setAuctionServerUpdate bid amount: ", sessionStorage[`auction-${id}`]);
      dispatch(setAuctionServerUpdate(id,sessionStorage[`auction-${id}`]));
       console.log("Dispatched setAuctionServerUpdate continue");
    }
 console.log("PAYPRODUCT QUANTITY", quantity);
    //console.log('---', amount);
//if time expired and auction then charge or change type to auctionwin or add another prop for auctionwin 
    userRef.once("value", (snapshot) => {
      if (snapshot.val()) {
          console.log("PAYPRODUCT continue: ", type);
          if (isSavedCard) {
              (type === "auction" &&  sessionStorage[`auctionWin-${id}`] == 0) 
              ? history.push(Routes.bidReceived)
              : dispatch(createCharge(amount, source, snapshot.val(), false, save, type, id, quantity));
          } else {
              (type === "auction" && sessionStorage[`auctionWin-${id}`] == 0) 
              ? dispatch(attachSource(source, snapshot.val(), save, () => {history.push(Routes.bidReceived)}))
              : dispatch(createChargeAndAttach(amount, source, snapshot.val(), false, save , type, id, quantity));
          }
      } else {
          (type === "auction" && sessionStorage[`auctionWin-${id}`] == 0) 
          ? dispatch(createCustomerToAuction(amount, source, save))
          : dispatch(createCustomerAndCharge(amount, source, save, type, id, quantity));
      }
      /*if(sessionStorage[`auctionWin-${id}`] == 1){
        history.push(`${Routes.productReceivedPage}/${'purchase'}/${type}/${id}`);
      }*/
    });


  }
};

export const createCustomerToAuction = (amount, source, save = false) => {
    return async (dispatch) => {

        const bodyData = new FormData();

        bodyData.append("source", source.id);
        bodyData.append("phone", app.auth().currentUser.phoneNumber);

        console.log("SID", source);

        /*      await fetch(
                  'https://staging-pn-stripe.herokuapp.com/createCustomer',
                  {
                      mode: 'no-cors', // no-cors
                      method: "POST",
                      body: bodyData,
                      headers: {
                          "Content-type":"text/html",
                      }
                  }
              )*/
        axiosStipe.post('/createCustomer', bodyData)
            .then((res) => {
                const {data} = res;
                if (res.status === 200) {

                    //let id = data['associated_objects'][0].id;
                    let customerId = data['customerid'];
                    //console.log('--ID', data);

                    dispatch(attachSource(source, customerId, save, () => {history.push(Routes.bidReceived)}));

                    dispatch(saveCustomer(customerId));
                }

            })
            .catch((error) => {
                console.log('AuctionError', error);
            });
    }
};

export const createCustomerAndCharge = (amount, source, save = false, type, id, quantity) => {
  return async (dispatch) => {
    const bodyData = new FormData();

      bodyData.append("source", source.id);
      bodyData.append("phone", app.auth().currentUser.phoneNumber);

/*      await fetch(
          'https://staging-pn-stripe.herokuapp.com/createCustomer',
          {
              mode: 'no-cors', // no-cors
              method: "POST",
              body: bodyData,
              headers: {
                  "Content-type":"text/html",
              }
          }
      )*/
    axiosStipe.post('/createCustomer', bodyData)
      .then(({data}) => {
        //let id = data['associated_objects'][0].id;
          let customerId = data['customerid'];
        console.log('Stripe Customer--ID', data);

              //dispatch(attachSource(source, customerId, save));
        //ADD BACK IN WILL 
        dispatch(saveCustomer(customerId));
        dispatch(createCharge(amount, source, customerId, true, save, type, id, quantity));
      })
      .catch((error) => {
        console.log('ephError', error);
      });
  }
};

export const createCharge = (amount, source, customer, newCustomer = false, save = false, type, id, quantity) => {
  return async (dispatch, getState) => {
    const bodyData = new FormData();

    bodyData.append('amount', amount);
    bodyData.append('source', source.id);
    bodyData.append('customer_id', customer);



/*      await fetch(
          'https://staging-pn-stripe.herokuapp.com/chargeCustomer',
          {
              mode: 'no-cors', // no-cors
              method: "POST",
              body: bodyData,
              headers: {
                  "Content-type":"text/html",
              }
          }
      )*/
    axiosStipe.post('/chargeCustomer', bodyData)
        .then((res) => {
            if (res.status === 200) {
                //console.log('rrr', res);

                if (newCustomer) {
                    dispatch(saveCustomer(customer));
                }

                     if(type === "deals"){
                        //console.log("DISPATCH SERVER UPDATE HERE DEALID: ", id);
                        dispatch(setDealsServerUpdate(id, quantity));
                      }
                     if(type === "raffle"){
                      // console.log("DISPATCH SERVER UPDATE HERE RAFFLEID: ", id);
                      // console.log("DISPATCH SERVER UPDATE HERE RAFFLE Quantity: ", quantity);
                        dispatch(setRaffleServerUpdate(id, quantity));
                     }




                   if (sessionStorage[`auctionWin-${id}`] == 1 && type === "auction") {
                            const productRef = app.database().ref(`Purchased_Products/Auction/${id}`);
                            productRef.once('value', (snapshot) => {
                                if (snapshot.val()) {
                                const lastKey = snapshot.node_.children_.root_.maxKey();
                                const stateRef= productRef.child(`/${lastKey}/state`);
                                stateRef.set("charged");  
                                productRef.child(`${lastKey}/charged`).set(true);
                                }
                            });
                      sessionStorage[`auctionWin-${id}`] = 2;
                      //dispatch(setAuctionServerUpdate(id,sessionStorage[`auction-${id}`])); 
                       history.push(Routes.bidReceived);
                      //dispatch(setAuctionWinCharged(id));
                      //dispatch(setOffer('auction', id, elem.bidMax));
                      //dispatch(fetchAuctionList());
                     
                      //history.push(`${Routes.productReceivedPage}/${'purchase'}/${type}/${id}`); 
                    } else { 
                      //dispatch(setAuctionServerUpdate(id,sessionStorage[`auction-${id}`]));
                      history.push(Routes.bidReceived);
                  }

                

            }
        })
        .catch((error) => {
            console.log('ChargeError', error);
            setTimeout(() => { history.replace(`${Routes.productPage}/${type}/${id}`);
            }, 3500);
          toast.error("Could not complete payment, try again!", {
            // Set to 5sec
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });

        });
  }
};

export const createChargeAndAttach = (amount, source, customer, newCustomer = false, save = false, type, id, quantity) => {
    return (dispatch, getState) => {
        const bodyData = new FormData();

        bodyData.append('amount', amount);
        bodyData.append('source', source.id);
        bodyData.append('customer_id', customer);
      


        dispatch(attachSource(source, customer, save, async () => {
/*            await fetch(
                'https://staging-pn-stripe.herokuapp.com/chargeCustomer',
                {
                    mode: 'no-cors', // no-cors
                    method: "POST",
                    body: bodyData,
                    headers: {
                        "Content-type":"text/html",
                    }
                }
            )*/


            axiosStipe.post('/chargeCustomer', bodyData)
                .then( async (res) => {
                    if (res.status === 200) {
                        //console.log('rrr', res);

                        if (newCustomer) {
                          dispatch(saveCustomer(customer));
                        }

                            if(type === "deals"){
                              // console.log("DISPATCH SERVER UPDATE HERE DEALID: ", id);
                              dispatch(setDealsServerUpdate(id, quantity));
                            }
                            if(type === "raffle"){
                               //console.log("DISPATCH SERVER UPDATE HERE RAFFLEID: ", id);
                               //console.log("DISPATCH SERVER UPDATE HERE RAFFLEID Quantity: ", quantity);
                              dispatch(setRaffleServerUpdate(id, quantity));
                            } 

                      

                        if (sessionStorage[`auctionWin-${id}`] == 1 && type === "auction"){
                            
                            const productRef = app.database().ref(`Purchased_Products/Auction/${id}`);
                            productRef.once('value', (snapshot) => {
                                if (snapshot.val()) {
                                const lastKey = snapshot.node_.children_.root_.maxKey();
                                const stateRef= productRef.child(`/${lastKey}/state`);
                                stateRef.set("charged"); 

                                productRef.child(`${lastKey}/charged`).set(true);
                                  
                                }
                             });
                            sessionStorage[`auctionWin-${id}`] = 2; 
                            history.push(Routes.bidReceived);
                            //dispatch(setAuctionServerUpdate(id,sessionStorage[`auction-${id}`]));
                            //dispatch(setOffer('auction', id, elem.bidMax));
                            //dispatch(fetchAuctionList());
                            //history.push(Routes.bidReceived);
                            //history.push(`${Routes.productReceivedPage}/${'purchase'}/${type}/${id}`); 
                          } else { 
                            //dispatch(setAuctionServerUpdate(id,sessionStorage[`auction-${id}`]));
                            //dispatch(setOffer(type, id, quantity));
                            history.push(Routes.bidReceived);
                        }
                       
                    }
                })
                .catch((error) => {
                    console.log('ChargeError', error);
                    //add popup error
                    setTimeout(() => {
                        history.replace(`${Routes.productPage}/${type}/${id}`);
                    }, 3500);
                    toast.error("Could not complete payment, try again!", {
                          // Set to 3sec
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                      });
                });
        }));




    }
};

export const saveCustomer = (customer) => {
  return (dispatch, getState) => {
      const state = getState();

      const {login} = state;

      const customerRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);

      customerRef.set(customer);

/*    const bodyData = new FormData();

    bodyData.append('api_version', '2015-10-12');
    bodyData.append('customer_id', customer);

    axios.post('/ephemeral_keys', bodyData)
      .then((res) => {
        const state = getState();

        const {login} = state;

        const customerRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);

        customerRef.set(customer);
      })
      .catch((error) => {
        console.log('saveError', error);
      });*/
  }
};

export const setStripleToken = (token) => {
  return {
    type: SET_STRIPLE_TOKEN,
    payload: token
  }
};

export const fetchSignOut = () => {
  return (dispatch) => {

    if (sessionStorage.stripleToken) {
      delete sessionStorage.stripleToken;
    }

        app
          .auth()
          .signOut()
          .catch((error) => {
            alert(error);
          })
    }
};

export const saveStripleToken = (source, customer) => {
  return (dispatch, getState) => {

      const state = getState();

      const {login} = state;

      const userRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/cards`);

      attachSource(source, customer, () => {
          const stripleTokenRef = userRef.child(source.id);

          //console.log("111", source);

          stripleTokenRef.set(source, () => {
              //dispatch(setStripleToken(source));
          });
      });
      //console.log('fff', source);
  }
};

export const attachSource = (source, customer, save = false, callback) => {
    return async (dispatch, getState) => {
        const bodyData = new FormData();

        bodyData.append("phone", app.auth().currentUser.phoneNumber);
        bodyData.append('source', source.id);
        bodyData.append('customer_id', customer);

/*        await fetch(
            'https://staging-pn-stripe.herokuapp.com/attachSource',
            {
                mode: 'no-cors', // no-cors
                method: "POST",
                body: bodyData,
                headers: {
                    "Content-type":"text/html",
                }
            }
        )*/
        axiosStipe.post('/attachSource', bodyData)
            .then(async (res) => {
                if (res.status === 200) {
                    //console.log('attach', res);

                    if (save) {
                        const state = getState();

                        const {login} = state;

                        const userRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/cards`);

                        //console.log('fff', source);

                        const stripleTokenRef = userRef.child(source.id);

                        //console.log("111", source);

                        stripleTokenRef.set(source, () => {
                            //dispatch(setStripleToken(source));
                        });
                    }

                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                console.log('attachError', error);
            });
    }
};



export const fetchSavedCards = () => {
  return (dispatch, getState) => {
    const state = getState();

    const {login} = state;

    const userRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/cards`);

    userRef.once('value', (snapshot) => {
      if (snapshot.val()) {
        dispatch(setStripleToken(snapshot.val()));
      }
    })
  }
};

export const saveStripleTokenToProduct = (token, type, id) => {
  return (dispatch, getState) => {
    const state = getState();

    console.log('ff', token, type);

    const userRef = app.database().ref(`/Purchased_Products/${getTypePath[type]}/${id}`);

/*    const stripleTokenRef = userRef.child(token.id);

    stripleTokenRef.set(token, () => {
      dispatch(setStripleToken(token));
    });*/

    userRef.once('value', (snapshot) => {
        if (snapshot.val()) {
          const lastKey = snapshot.node_.children_.root_.maxKey();

          userRef.child(`${lastKey}/source`).set(token);

          if (type === "auction") {
              userRef.child(`${lastKey}/charged`).set(false);
          }
        }
    });
  }
};

const getTypePath = {
  'raffle': 'Raffle',
  'auction': 'Auction',
  'deals': 'Deals'
};