import React from 'react';

import './style.scss';

class QuantityChanger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changerCurrentQuantity: 0,
      changerMinBid: 0,
      maxInventory: 0
    }
  }

  setQuantityValues = (currentQuantity, minBidValue, maxInventory) => {
    const {onBidChange} = this.props;

    const newChangerBid = parseInt(currentQuantity) + parseInt(minBidValue);

      console.log("MAX INVENTORY IN QUANTITY CHANGER:", maxInventory);
    this.setState({
      changerCurrentQuantity: newChangerBid,
      changerMinBid: newChangerBid,
      maxInventory : maxInventory,
      interval: parseInt(minBidValue)
    }, () => {
      onBidChange(newChangerBid);
    });
  };

  setCurrentBid = (bid) => {
    const {onBidChange} = this.props;

    this.setState({
      changerCurrentQuantity: bid
    }, () => {
      onBidChange(bid);
    });
  };

  componentDidMount() {
    const {currentQuantity, minBidValue, maxInventory, intervalBidValue} = this.props;

    this.setQuantityValues(currentQuantity, minBidValue, maxInventory, intervalBidValue);
  }

  componentWillReceiveProps(nextProps) {
    const {currentQuantity, minBidValue, maxInventory, intervalBidValue} = this.props;

    if (currentQuantity !== nextProps.currentQuantity || minBidValue !== nextProps.minBidValue || intervalBidValue !== nextProps.intervalBidValue || maxInventory !== nextProps.maxInventory) {
      this.setQuantityValues(nextProps.currentQuantity, nextProps.minBidValue, nextProps.intervalBidValue , nextProps.maxInventory);
    }
  }

  onBidClick = (approach) => {
    const {changerCurrentQuantity, changerMinBid, maxInventory, interval} = this.state;
    let result = changerMinBid;
    //interval = 1;
    if (approach === 'plus') {
      result = changerCurrentQuantity + interval <= maxInventory ? changerCurrentQuantity + interval : changerCurrentQuantity;

    } else if (approach === 'minus') {
      if (changerCurrentQuantity - interval >= changerMinBid) {
        result = changerCurrentQuantity - interval;
      }
    }

    if (changerCurrentQuantity !== result) {
      this.setCurrentBid(result)
    }
  };

  render() {
    const {changerCurrentQuantity, changerMinBid, maxInventory} = this.state;

    return (
      <div className="bid-changer-wrapper">
        <div
          className={`bid-changer-control ${changerCurrentQuantity <= 1 ? 'deactivated-control' : ''}`}
          onClick={() => {this.onBidClick('minus')}}
        >
          <i className="fas fa-minus" />
        </div>
        <div className="bid-changer-display">
          <h4>Quantity</h4>
          <span>{`${changerCurrentQuantity}`}</span>
        </div>
        <div
          className={`bid-changer-control ${changerCurrentQuantity >= maxInventory ? 'deactivated-control' : ''}`}
          onClick={() => {this.onBidClick('plus')}}
        >
          <i className="fas fa-plus" />
        </div>
      </div>
    )
  }
}

export default QuantityChanger;