import React from 'react';
import Info from '../../components/Info';
import app from "../../base";
import BackButton from '../../components/BackButton';
import PageWrapper from '../../components/PageWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import './style.scss';

class HelpContainer extends React.Component {



      constructor(props) {
        super(props);

        this.state = {
         // text: "RAFFLE: Raffle entries can be purchased individually and each raffle entry will increase your odds of winning the prize. Each raffle is charged to the buyer immediately upon purchase. \n AUCTION: Auctions are won by the highest bidder within the allotted timer period. Bids are not charged until the winner is decided. \n DEALS: Deals can be purchased anytime within the given length of the timer. Please visit a PowNow representative to redeem your merchandise. \n  Visit a PowNow representative or email us at poppstechnologies@gmail.com if you have any additional questions.";
            paragraphData: [
                {id: 1, text: "RAFFLE: Raffle entries can be purchased individually and each raffle entry will increase your odds of winning the prize. Each raffle is charged to the buyer immediately upon purchase."},
                {id: 2, text: "AUCTION: Auctions are won by the highest bidder within the allotted timer period. Bids are not charged until the winner is decided."},
                {id: 3, text: "DEALS: Deals can be purchased anytime within the given length of the timer. Please visit a PowNow representative to redeem your merchandise."},
                {id: 4, text: "Visit a PowNow representative or email us at poppstechnologies@gmail.com if you have any additional questions."},
            ]
        }
    }

    render() {
        const {paragraphData} = this.state;

        return (
            <>
                <div className="container">
                    <div className="header">
                        <BackButton />
                    </div>
                    <div className="paragraph-container">
                        {paragraphData.map((item, index) => (
                            <p key={index}>{item.text}</p>
                        ))}
                    </div>
                </div>
            </>
        )
    }


 /* componentDidMount() {
    const termsRef = app.database().ref(`/help`);

    termsRef.once('value', (snapshot) => {
      if (snapshot.val()) {
        console.log("HELP TEXT", snapshot.val());
        //this.setState({
        //  text: snapshot.val()
        //})
      }
    });
  }*/

}

export default HelpContainer;