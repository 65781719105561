import {
  BASE_URL,
  STRIPE_URL,
  SET_AUCTION_LIST,
  SET_RAFFLE_LIST,
  SET_DEALS_LIST,
  SET_PRODUCT_OFFER,
  SET_PURCHASE_PRODUCTS,
  SET_PARTICIPANTS_PRODUCTS
} from "./const";
import axios from 'axios';
import moment from 'moment/moment.js'
import history from '../history';
import Routes from '../routes';
import app from "../base";
import {getPurchaseCountdown} from '../utils';
import {createCharge, saveCustomer} from '../actions/index';
import {useEffect, useState} from "react";

const axiosStipe = axios.create({
    baseURL: STRIPE_URL,
    timeout: 1000,
    headers: {'Content-type':'application/json'}
});

const isDevelopment = process.env.NODE_ENV === 'development';
const target = isDevelopment ? 'stripeDev' : 'stripe';

const setOffer = (type, id, bid) => {
  //bid for auction else bid is quantity for deals and raffle 
  return {
    type: SET_PRODUCT_OFFER,
    payload: {type, id, bid}
  }
};

const setProductsList = (type, products, state) => {
  return {
    state,
    type,
    payload: products
  }
};


const setPurchaseProducts = (products) => {
  return {
    type: SET_PURCHASE_PRODUCTS,
    payload: products
  }
};

const setParticipantsProducts = (products) => {
  return {
    type: SET_PARTICIPANTS_PRODUCTS,
    payload: products
  }
};

const setPurchaseToFirebase = ({type, id, count}, callback) => {
  return (dispatch, getState) => {
    const state = getState();

    const {login} = state;

    const product = state.products[type].filter(elem => elem.id === id)[0];

    let quantity = 1;

    //const userRef = app.database().ref(`/users/${login.user.phoneNumber}`);

    const productRef = app.database().ref(`Purchased_Products/${getTypePath[type]}/${id}`);

    let productConfig = {
      //...product,
      productId: product.id,
      name: product.title,
      purchaseTime: moment().format('YYYY-MM-DD HH:mm:ss ZZ'),
      endTime:product.endTime,
      type: getTypePath[type],
      phoneNumber: login.user.phoneNumber,
      quantity: 1,
      bidAmount: 0,
      bidPrice: " ",
      state: " ",
      sellerImage: product.sellerImage,
      description: product.description,
      //winner: product.raffleWinner
    };

    if (product.state) {
      productConfig.state = product.state;
    }

    productConfig.productId = id;
    productConfig.phoneNumber = login.user.phoneNumber;
    //console.log("What's my count Firebase?:", count);

    //delete productConfig.id;
    //delete productConfig.title;
    //delete productConfig.startTime;

    productRef.once('value', (snapshot) => {
      let childInx = 1;
      let itemId = 1;
     

      if (type === 'auction') {
        productConfig.bidAmount = count;

        //delete productConfig.currentBidValue;
        productConfig.imageName = product.bidImage;

        //delete productConfig.bidImage;
      } else {

        if (type === 'raffle') {
          productConfig.imageName = product.raffleImage;
          productConfig.bidAmount = count;
          //quantity = count;
          //delete productConfig.raffleImage;
        } else {
          productConfig.imageName = product.dealImage;
          productConfig.bidAmount = count;
          //quantity = count;
          //delete productConfig.dealImage;
        }

        productConfig.bidPrice = product.price;

        //delete productConfig.price;
        //delete productConfig.limitQuantity;
      }

      console.log('PRODUCT ACTIONS productConfig count', count);
      console.log('PRODUCT ACTIONS productConfig quantity', quantity);
      console.log('PRODUCT ACTIONS productConfig quantity2', productConfig.quantity);
      console.log('PRODUCT ACTIONS product', product);

      let lastCount = 0;

      if (snapshot.val()) {
        const snapshotData = snapshot.val();

        let lastObj = {};

        const lastKey = snapshot.node_.children_.root_.maxKey();

        lastObj = snapshot.val()[lastKey];


        let lastId = parseInt(lastObj['itemId']) || 1;
        lastCount = lastObj['quantity'] || 1;


        if (type === 'raffle' || type === 'deals') {
          //let newQuantity = 1;
          let newQuantity = quantity;

          let lastCurrentObj = {};

          snapshot.forEach((childSnapshot) => {
            const childValue = childSnapshot.val();

            let lastObjKey = parseInt(lastCurrentObj['itemId']) || 0;

            if (childValue["phoneNumber"] === login.user.phoneNumber && childValue["itemId"] > lastObjKey) {
              lastCurrentObj = childValue;
            }
          });

          if (lastCurrentObj["itemId"]) {
            //newQuantity = lastCurrentObj['quantity'] + 1;
            newQuantity = lastCurrentObj['quantity'] + quantity;
          }

          productConfig.quantity = newQuantity;
        }

        //Will Changed Aug 17th 2023 // Update Quantity by one at a time
        //childInx = lastId + lastCount;
        //childInx = lastId + 1;
        childInx = lastId + quantity;
        itemId = childInx;
      }

      productConfig.itemId = "" + itemId;

      //productRef.child(`/${childInx}`).set(productConfig, (error) => {callback(error, lastCount + 1)});
      productRef.child(`/${childInx}`).set(productConfig, (error) => {callback(error, lastCount + quantity)});
    });
  }
};

export const setNullOffer = () => {
  return (dispatch) => {
    dispatch(setOffer());

    history.push(Routes.main);
  }
};



export const setAuctionServerUpdate = (id , bid) =>{
  return (dispatch, getState)=> {

    let route = Routes.bidReceived;

    console.log("Starting UPDATING BID TO SERVER");
    const bodyData = new FormData();

    bodyData.append('idBid', id);
    bodyData.append('highestBid', bid);
    console.log("idBID",id);
    console.log("highestBid", bid);

    axios.post(`${BASE_URL}/temp/updateBid.php`, bodyData)
      .then(() => {
        console.log("FINISH UPDATING BID TO SERVER");
        dispatch(setPurchaseToFirebase({type: 'auction', id, count: bid}, (error) => {
          if (error) return;

          dispatch(setOffer('auction', id, bid));
          dispatch(fetchAuctionList());
          sessionStorage[`auction-${id}`] = bid;
          //history.push(route);
        }));

      })
      .catch((error) => {
        console.log("ERROR UPDATING BID TO SERVER");
        alert(error);
      });


  }
};

export const setDealsServerUpdate = (id, quantity) =>{
  return (dispatch, getState)=> {

    let route = Routes.stripleCheckout;

    console.log("Starting UPDATING Deal TO SERVER");


  const bodyData = new FormData();

    bodyData.append('idDeals', id);
    bodyData.append('quantityBought', quantity);



    axios.post(`${BASE_URL}/temp/updateDeals.php`, bodyData)
      .then(() => {

        dispatch(setPurchaseToFirebase({type: 'deals', id, count: quantity}, (error) => {
          //if (error) return;

          dispatch(setOffer('deals', id, quantity));
          dispatch(fetchDealsList());
          //history.push(Routes.stripleCheckout);
        }));

      })
      .catch((error) => {
        alert(error);
      });

  }
};

export const setRaffleServerUpdate = (id, quantity) =>{
  return (dispatch, getState)=> {

    let route = Routes.stripleCheckout;
    let count = quantity; 

    //console.log("Saving Raffle TO Firebase");

//console.log("Saving Raffle TO Firebase setRaffleServerUpdate PRE Quantity:",quantity );
        dispatch(setPurchaseToFirebase({type: 'raffle', id, count: quantity}, (error) => {
          //if (error) return;
//console.log("Saving Raffle TO Firebase setRaffleServerUpdate Quantity:",quantity );
          dispatch(setOffer('raffle', id, quantity));
          dispatch(fetchRaffleList());
          history.push(Routes.bidReceived);
        }));
  }
};

export const setAuctionOffer = (id, bid) => {
  return (dispatch, getState) => {

    const {login} = getState();

    const userRef = app.database().ref(`/users/${login.user.phoneNumber}`);

    const productRef = userRef.child(`purchases/auction-${id}`);

    let route = Routes.stripleCheckout;


    //added Dec 18th '23 for continued flow 
          dispatch(setOffer('auction', id, bid));
          dispatch(fetchAuctionList());
          sessionStorage[`auction-${id}`] = bid;
          history.push(route);

/*    if (sessionStorage.token && sessionStorage[`auction-${id}`]) {
      productRef.once('value', (snapshot) => {
        if (snapshot.val()) {
          route = Routes.bidReceived;
        }
      });
    }*/
    
    //Took out from here
  /*  console.log("Starting UPDATING BID TO SERVER");
    const bodyData = new FormData();

    bodyData.append('idBid', id);
    bodyData.append('highestBid', bid);
    console.log("idBID",id);
    console.log("highestBid", bid);

    axios.post(`${BASE_URL}/temp/updateBid.php`, bodyData)
      .then(() => {
        console.log("FINISH UPDATING BID TO SERVER");
        dispatch(setPurchaseToFirebase({type: 'auction', id, count: bid}, (error) => {
          if (error) return;

          dispatch(setOffer('auction', id, bid));
          dispatch(fetchAuctionList());
          sessionStorage[`auction-${id}`] = bid;
          history.push(route);
        }));

      })
      .catch((error) => {
        console.log("ERROR UPDATING BID TO SERVER");
        alert(error);
      });*/
    }
};

export const setDealsOffer = (id , quantity) => {
  return (dispatch, getState) => {

     const state = getState();
     const {login} = getState();

     let route = Routes.stripleCheckout;

    const product = state.products['deals'].filter(elem => elem.id === id)[0];

    const limit = product.limitQuantity || 0;
 

    if (parseInt(limit) <= 0) return;
  

      
          //if (error) return;

          dispatch(setOffer('deals', id, quantity));
          dispatch(fetchDealsList());
          history.push(route);

     //history.push(Routes.stripleCheckout);

    /*const bodyData = new FormData();

    bodyData.append('idDeals', id);
    bodyData.append('quantityBought', '1');



    axios.post(`${BASE_URL}/temp/updateDeals.php`, bodyData)
      .then(() => {

        dispatch(setPurchaseToFirebase({type: 'deals', id, count: 1}, (error, quantity) => {
          if (error) return;

          dispatch(setOffer('deals', id, quantity));
          dispatch(fetchDealsList());
          history.push(Routes.stripleCheckout);
        }));

      })
      .catch((error) => {
        alert(error);
      });*/
  }
};

export const setRaffleOffer = (id, quantity) => {
  return (dispatch) => {


    let route = Routes.stripleCheckout;

    //dispatch(setPurchaseToFirebase({type: 'raffle', id, count: 1}, (error, quantity) => {
      //if (error) return;
      dispatch(setOffer('raffle', id, quantity));
      dispatch(fetchRaffleList());
      history.push(route);
    //}))

  }
};

export const fetchAuctionList =  () => {
//console.log("fetchAuctionList");
  
  return (dispatch,getState) => {
    axios({
      url: `${BASE_URL}/temp/api_bids.php?key=0`,
      method: 'get',
      headers: {
        'Cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'content-type': 'application/json'
      }
    })
      .then(({data}) => {

        const prod = data.data;
        //console.log("fetchAuctionList AXIOS", prod);
        dispatch(setProductsList(SET_AUCTION_LIST, prod,Math.random()))
        //this.setState(state);
      })
      .catch((error) => {
      });
  }
};

export const fetchRaffleList = () => {
  //console.log("fetchRaffleList");
  return (dispatch,getState) => {
   
    axios({
      url: `${BASE_URL}/temp/api_raffle.php?key=0`,
      method: 'get',
      headers: {
        'Cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'content-type': 'application/json'
      }
    })
      .then(({data}) => {

        const prod = data.data;

        //prod[1].bidState = 'win';
       // console.log("fetchRaffleList AXIOS", prod);
        
        dispatch(setProductsList(SET_RAFFLE_LIST, prod, Math.random()))
        //this.setState(state);
      })
      .catch((error) => {
        alert(error);
      });
  }
};

export const fetchDealsList = () => {
 // console.log("fetchDealList");
  return (dispatch,getState) => {
    
    axios({
      url: `${BASE_URL}/temp/api_deals.php?key=0`,
      method: 'get',
      headers: {
        'Cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'content-type': 'application/json'
      }
    })
      .then(({data}) => {
         //console.log("fetchDealList AXIOS", data.data);
       
        dispatch(setProductsList(SET_DEALS_LIST, data.data,Math.random()))
        //this.setState(state);
      })
      .catch((error) => {
        alert(error);
      });
  }
};

export const fetchPurchasesList = () => {
  return (dispatch, getState) => {

    const {login} = getState();


    const purchasesRef = app.database().ref('Purchased_Products/');

    let customer = null;

  const customerRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);

  const userRef = app.database().ref(`/users/${login.user.phoneNumber}`);

  //let auctionProd = dispatch(getAuctionList());

  //console.log("Auction Products.JS Auction Products", auctionProd);

  customerRef.once('value', (snapshot) => {
     if (snapshot.val()) {
         customer = snapshot.val();
     }
  });

    let purchases = [];

    purchasesRef.once('value',(snapshot) => {
      if (snapshot.val()) {

        snapshot.forEach((childSnapshot) => {

          childSnapshot.forEach((child2Snapshot) => {

            let lastCurrentObj = {};
            let maxBid = 0;

            child2Snapshot.forEach((child3Snapshot) => {
              const childValue = child3Snapshot.val();

              if ((childValue.type === 'Auction' || childValue.type === 'auction') && childValue.bidAmount > maxBid) {
                maxBid = childValue.bidAmount;
              }

              let lastObjKey = parseInt(lastCurrentObj['itemId']) || 0;

              if (childValue["phoneNumber"] === login.user.phoneNumber && childValue["itemId"] > lastObjKey) {
                lastCurrentObj = childValue;
              }
            });

            if (lastCurrentObj.type === 'Auction' || lastCurrentObj.type === 'auction') {
              lastCurrentObj.bidMax = maxBid;
            }

            if (lastCurrentObj["itemId"]) {
              let currentType = lastCurrentObj.type;

              lastCurrentObj.type = getOldType[currentType];

              lastCurrentObj.id = lastCurrentObj.productId;

              //console.log("Auction Products.JS Last CurrObj", lastCurrentObj);
              //can change to if not expired
              if (lastCurrentObj["state"] !== "charged") {
                delete  lastCurrentObj["state"];
              }

              purchases.push(lastCurrentObj);
              //console.log("Get last object in purchases:", lastCurrentObj.productId);
            }

          });
        });


      purchases = purchases.filter(elem => {
          return elem.phoneNumber === login.user.phoneNumber;// && getPurchaseCountdown(elem.data, 48 * 60) !== 0;
        });

       /*   purchases.forEach((elem) => {
           if (elem.type === 'auction') {
               //console.log("Auction Products.JS", elem);
                //chargeAuctionWinPurchases(elem.id);
                if (elem.state === "win" && elem.charged === false && elem.source) {
                    const bodyData = new FormData();

                    bodyData.append('amount', elem.bidAmount * 100);
                    bodyData.append('source', elem.source.id);
                    bodyData.append('customer_id', customer);

                    axiosStipe.post('/chargeCustomer', bodyData)
                        .then((res) => {
                            if (res.status === 200) {
                                const productRef = purchasesRef.child(`Auction/${elem.id}/${elem.itemId}`);

                                productRef.child('charged').set(true);
                            }
                        })
                        .catch((error) => {
                            console.log('ChargeAuctionError', error);
                        });
                }
           }
        });

   */     
        dispatch(fetchRaffleList());
        dispatch(fetchDealsList());
        dispatch(fetchAuctionList());
      }

      dispatch(setPurchaseProducts(purchases));
    });
  }
};

export const fetchParticipantsList = (type, id) => {
  return (dispatch, getState) => {

    const {login} = getState();

    console.log("GET TYPE PARTICIPANT:", getTypePath[type]);
    const purchasesRef = app.database().ref('Purchased_Products/');


    //let participants = [];
    let participants = new Set();
    let participantNames = [];

    purchasesRef.once('value',(snapshot) => {
      if (snapshot.val()) {

        snapshot.forEach((childSnapshot) => {

          childSnapshot.forEach((child2Snapshot) => {

           
            child2Snapshot.forEach((child3Snapshot) => {
              const childValue = child3Snapshot.val();

               if((childValue.type == type || childValue.type == getTypePath[type]) && childValue.productId == id){
                //console.log("Get last object in purchases for Participants:", lastCurrentObj.type + lastCurrentObj.productId);
                childValue.name = "TBD";
                //participants.push(childValue);
                participants.add(childValue.phoneNumber);
              }
            });


          });
        });


     
      const usersRef = app.database().ref('/users');
      usersRef.once('value',(snapshot) => {
        if(snapshot.val()){
          snapshot.forEach((userSnapshot) => {
            const userValue = userSnapshot.val();

            console.log("USERVALUEKEY:", userSnapshot.key);

            /*participants.forEach((participant)=>{ 
              if(participant.phoneNumber == userValue){
                participantsNames.push(userValue);
              }
            });*/

            /*if(participants.includes(userValue.val)){
              participantsNames.push(userValue.value);
            }*/
            
            //let participant = [];
           /*let isParticipant = participants.includes(elem=> {
              return elem.phoneNumber === userSnapshot.key;
            });*/

           //let isParticipant = participants.findIndex(elem => {return elem.phoneNumber === userSnapshot.key}) != -1;

           //let isParticipant = participants.some(elem => { return elem.phoneNumber === userSnapshot.key});

            //console.log("PARTICIPANT:", isParticipant);
            //if(isParticipant){//participant.length != 0){
            if(participants.has(userSnapshot.key)){
              console.log("USERVALUE:", userValue);
              participantNames.push(userValue.name);
            }            

          });
           console.log("DISPATCH PARTICPANTSLIST");
        dispatch(setParticipantsProducts(participantNames));
        }
      });
     

       /*participants = participants.filter(elem => {
          return  elem.productId === id;// && getPurchaseCountdown(elem.data, 48 * 60) !== 0;
        });*/
      /* dispatch(fetchRaffleList());
        dispatch(fetchDealsList());
        dispatch(fetchAuctionList());*/

      }
      //console.log("DISPATCH PARTICPANTSLIST");
      //dispatch(setProductsList(SET_PARTICIPANTS_PRODUCTS, participants, Math.random()));
      
      //dispatch(setParticipantsProducts(participantNames));
    });




  }
};


export const chargeAuctionWinPurchases = (id) => {
  return(dispatch, getState) => {

    //const {id} = this.props;
    const {login} = getState();

    //The following two declarations are used for Stripe Checkout
   // const userRef = app.database().ref(`/users/${login.user.phoneNumber}`);
   // const productRef = userRef.child(`purchases/auction-${id}`);

    const purchasesRef = app.database().ref('Purchased_Products/Auction/');

    let customer = null;

  const customerRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);

  //const userRef = app.database().ref(`/users/${login.user.phoneNumber}`);

  //let auctionProd = dispatch(getAuctionList());

  //console.log("Auction Products.JS Auction Products", auctionProd);

  customerRef.once('value', (snapshot) => {
     if (snapshot.val()) {
         customer = snapshot.val();
     }
  });

    let purchases = [];
    //let maxBid = 0;

    purchasesRef.once('value',(snapshot) => {
      if (snapshot.val()) {

        //snapshot.forEach((childSnapshot) => {

          snapshot.forEach((childSnapshot) => {

            let lastCurrentObj = {};
            let maxBid = 0;

            childSnapshot.forEach((child3Snapshot) => {
              const childValue = child3Snapshot.val();

              if ((childValue.type === 'Auction' || childValue.type === 'auction') && childValue.bidAmount > maxBid) {
                maxBid = childValue.bidAmount;
              }

              let lastObjKey = parseInt(lastCurrentObj['itemId']) || 0;

              if (childValue["phoneNumber"] === login.user.phoneNumber && childValue["itemId"] > lastObjKey) {
                lastCurrentObj = childValue;
              }
            });

            if (lastCurrentObj.type === 'Auction' || lastCurrentObj.type === 'auction') {
              lastCurrentObj.bidMax = maxBid;
            }

            if (lastCurrentObj["itemId"]) {
              let currentType = lastCurrentObj.type;

              lastCurrentObj.type = getOldType[currentType];

              lastCurrentObj.id = lastCurrentObj.productId;

              //console.log("Auction Products.JS Last CurrObj", lastCurrentObj);
              if (lastCurrentObj["state"] !== "charged") {
                delete  lastCurrentObj["state"];
              }


              purchases.push(lastCurrentObj);
            }

          });
        //});


        purchases = purchases.filter(elem => {
          return elem.phoneNumber === login.user.phoneNumber; //&& getPurchaseCountdown(elem.data, 48 * 60) !== 0;
        });

        purchases.forEach((elem) => {
           if (elem.type === 'auction' && elem.id === id) {
               console.log("ELEM FOUND to Update Charge Auction", elem);
               console.log("ELEM CHARGED?", elem.state);
               console.log("ELEM source?", elem.source);
                if (elem.state !== "charged" && elem.source) {
//(elem.charged !== true ||
                console.log("ELEM Not CHARGED Continue", elem.state);
                    const bodyData = new FormData();

                    bodyData.append('amount', elem.bidAmount * 100);
                    bodyData.append('source', elem.source.id);
                    bodyData.append('customer_id', customer);

//ADDED *******
 const productRef = app.database().ref(`/Purchased_Products/Auction/${id}`);
 //const userStripeRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);

    productRef.once('value', (snapshot) => {
        if (snapshot.val()) {
          const lastKey = snapshot.node_.children_.root_.maxKey();

          const productSourceRef = productRef.child(`${lastKey}/source`);

          if (elem.type === "auction") {

                //StripeREF ADDED ********

                 // userStripeRef.once("value", (snapshotStripe) => {
                   // if (snapshotStripe.val()) {
                   // console.log("USER SOURCE VALUE: ",userSourceRef.val());
                          productSourceRef.once("value", (snapshotSource) => {
                            console.log("SOURCE VALUE: ",snapshotSource.val());
                            if (snapshotSource.val()){
                            console.log("CHARGING CUSTOMER");
                            dispatch(createCharge((elem.bidAmount * 100), snapshotSource.val(), customer, false, true));
                                productRef.child(`${lastKey}/state`).set("charged");
                                const productItemRef = purchasesRef.child(`${elem.id}/${elem.itemId}`);
                                productItemRef.child(`state`).set("charged");
                                productItemRef.child(`charged`).set(true);
                              //may need to add auction state
                               //history.push(Routes.stripleCheckout);
                            }

                          });
                    //    }
                  //});

                //StripeRED ADDED ********
        

          }
        }
    });

//ADDED ********

      //Take out for now *****
      /*
                    axiosStipe.post('/chargeCustomer', bodyData)
                        .then((res) => {
                            if (res.status === 200) {
                                const productRef = purchasesRef.child(`Auction/${elem.id}/${elem.itemId}`);
                                productRef.child('charged').set(true);
                                console.log("Auction CHARGED", productRef);
                            }
                        })
                        .catch((error) => {
                            console.log('ChargeAuctionError', error);
                        });
      */
     //Take out for now *****                   
                } else {
                  console.log("ELEM CHARGED HERE:", elem.state);
                  if(elem.state === "charged"){
                    history.push(`${Routes.productReceivedPage}/${'purchase'}/Auction/${id}`)
                  }
                  console.log("go to payment PAGE");

                   dispatch(setPurchaseToFirebase({type: 'auction', id, count: elem.bidMax}, (error) => {
                    if (error) return;

                    dispatch(setOffer('auction', id, elem.bidMax));
                    dispatch(fetchAuctionList());
                    sessionStorage[`auction-${id}`] = elem.bidMax;
                    sessionStorage[`auctionWin-${id}`] = 1;
                    history.push(Routes.stripleCheckout);
                  }))
       
                }
           }
        });

        //dispatch(fetchRaffleList());
        //dispatch(fetchDealsList());
       // dispatch(fetchAuctionList());
      }

      //dispatch(setPurchaseProducts(purchases));
    });
  }
};








/*export const setAuctionWinCharged = (id) => {
  return(getState) => {

    //const {id} = this.props;
    const {login} = getState();

    //The following two declarations are used for Stripe Checkout
    const userRef = app.database().ref(`/users/${login.user.phoneNumber}`);
    const productRef = userRef.child(`purchases/auction-${id}`);

    const purchasesRef = app.database().ref('Purchased_Products/Auction/');

    let customer = null;

  const customerRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);


  customerRef.once('value', (snapshot) => {
     if (snapshot.val()) {
         customer = snapshot.val();
     }
  });

    let purchases = [];

    purchasesRef.once('value',(snapshot) => {
      if (snapshot.val()) {

        //snapshot.forEach((childSnapshot) => {

          snapshot.forEach((childSnapshot) => {

            let lastCurrentObj = {};
            let maxBid = 0;

            childSnapshot.forEach((child3Snapshot) => {
              const childValue = child3Snapshot.val();

              if ((childValue.type === 'Auction' || childValue.type === 'auction') && childValue.bidAmount > maxBid) {
                maxBid = childValue.bidAmount;
              }

              let lastObjKey = parseInt(lastCurrentObj['itemId']) || 0;

              if (childValue["phoneNumber"] === login.user.phoneNumber && childValue["itemId"] > lastObjKey) {
                lastCurrentObj = childValue;
              }
            });

            if (lastCurrentObj.type === 'Auction' || lastCurrentObj.type === 'auction') {
              lastCurrentObj.bidMax = maxBid;
            }

            if (lastCurrentObj["itemId"]) {
              let currentType = lastCurrentObj.type;

              lastCurrentObj.type = getOldType[currentType];

              lastCurrentObj.id = lastCurrentObj.productId;

              //console.log("Auction Products.JS Last CurrObj", lastCurrentObj);
              if (lastCurrentObj["state"] !== "charged") {
                delete  lastCurrentObj["state"];
              }


              purchases.push(lastCurrentObj);
            }

          });


        purchases = purchases.filter(elem => {
          return elem.phoneNumber === login.user.phoneNumber && getPurchaseCountdown(elem.data, 48 * 60) !== 0;
        });

        purchases.forEach((elem) => {
           if (elem.type === 'auction' && elem.id === id) {
               console.log("ELEM FOUND to Update Charge Auction", elem);
               console.log("ELEM CHARGED?", elem.state);
               console.log("ELEM source?", elem.source);
               elem.state = "charged";
               /* if (elem.state !== "charged" && elem.source) {

                console.log("ELEM Not CHARGED Continue", elem.state);
                    const bodyData = new FormData();

                    bodyData.append('amount', elem.bidAmount * 100);
                    bodyData.append('source', elem.source.id);
                    bodyData.append('customer_id', customer);*/

//ADDED *******
/* const userRef = app.database().ref(`/Purchased_Products/Auction/${id}`);
 //const userStripeRef = app.database().ref(`/users/${login.user.phoneNumber}/${target}/customerID`);

    userRef.once('value', (snapshot) => {
        if (snapshot.val()) {
          //potentially need to filter until matches phone# && bid = maxBid 
          const lastKey = snapshot.node_.children_.root_.maxKey();

          const productRef = purchasesRef.child(`${elem.id}/${elem.itemId}`);
          const productRefState = purchasesRef.child(`${elem.id}/${elem.itemId}/state`);
          const productRefCharged = purchasesRef.child(`${elem.id}/${elem.itemId}/charged`);
          productRefState.set("charged");
          productRefCharged.set(true);
          userRef.child(`${lastKey}/state`).set("charged");

          /*if (elem.type === "auction") {
                          productRef.once("value", (snapshotSource) => {
                            console.log("SOURCE VALUE: ",snapshotSource.val());
                            if (snapshotSource.val()){
                                
                                //const productRef = purchasesRef.child(`${elem.id}/${elem.itemId}`);
                                productRef.child(`state`).set("charged");
                                //elem.charged = true;
                            } 

                          });
                

        

          }*/
 /*       }
    });

                //}
           }
        });

        //dispatch(fetchRaffleList());
        //dispatch(fetchDealsList());
       // dispatch(fetchAuctionList());
      }

    });
  }
};*/

const getTypePath = {
  'raffle': 'Raffle',
  'auction': 'Auction',
  'deals': 'Deals'
};

const getOldType = {
  'Raffle': 'raffle',
  'Auction': 'auction',
  'Deals': 'deals'
};

const getNormalizeArray = (content) => {
  let result = [];

  if (Array.isArray(content)) {
    result = content;
  } else {
    for (let key in content) {
      result[parseInt(key)] = content[key];
    }
  }

  return result;
};