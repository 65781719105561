import React from 'react';
import {BidLogo} from "../BidComponents";
import PurchasesTimer from '../../components/PurchasesExpireTimer';

import './style.scss';

//TODO: Can change to function?

class PurchasesCard extends React.Component {
  getInfo() {
    const {type, count} = this.props;

    let result;

    switch (type) {
      case 'auction':
        result = `Bid: $${count}`;
        break;
      case 'raffle':
        result = `Entries: ${count}`;
        break;
      default: {
        result = `Quantity: ${count}`;
      }
    }

    return result;
  }

  getTimeBought(){
    const{purchaseData} = this.props;

    let result = purchaseData;

    return result;

  }

  render() {
    const {title, image, purchaseData, fetchPurchasesList} = this.props;

    return (
      <div className="purchases-card">
        <BidLogo
          link={image}
        />
        <div className="purchases-card-content">
          <h2 className='bid-card-title' style={{whiteSpace:"pre-wrap"}}>{title.padEnd(28,' ')}</h2>

          <div className="purchases-card-info">
            <span className="card-count-info">
              {this.getInfo()}
            </span>
              
            <span className="card-date-info">
              {`${purchaseData}`.substring(0,10)}
            </span>

              <div className="purchases-timer-info">
                <PurchasesTimer
                  fetchPurchasesList={fetchPurchasesList}
                  purchaseData={purchaseData}
                />
              </div>


          </div>
        </div>
      </div>
    )
  }
}

export default PurchasesCard;