import React from 'react';
import PageWrapper from '../../components/PageWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import BackButton from '../../components/BackButton';
import BidChanger from '../../components/BidChanger';
import QuantityChanger from '../../components/QuantityChanger';
import CustomButton from '../../components/CustomButton';
import moment from 'moment/moment.js'
import {BidLogo, BidCover, CurrentBid, BidTimer, Utils} from "../../components/BidComponents";


import './style.scss';

class ProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changerCurrentBid: 0,
      currentBidValue : 0,
    }
  }

  onBidChange = (value) => {
    const {product} = this.props;
    this.setState({
      changerCurrentBid: value,
      currentBidValue: product.currentBidValue,
    })
  };

  getButtonText = () => {
    const {type} = this.props;
    let result = 'bid';

    if (type === 'raffle' || type === 'deals')
      result = 'purchase';

    return result;
  };

    getExpiredTime = () => {
    const {product} = this.props;
    const endTimeMoment = moment(product.endTime, 'YYYY-MM-DD HH:mm:ss');
    const currMoment = moment().format('YYYY-MM-DD HH:mm:ss');
 
    console.log("PRODUCT ENDTIME:",product.endTime);
     console.log("Moment CurrTime:",currMoment);
    let result = endTimeMoment.isAfter(moment(currMoment ,'YYYY-MM-DD HH:mm:ss')) ? false : true

    return result;
  };

  getProductInfo = (product) => {
    const {type, participants} = this.props;
      console.log("PRODUCT GET:",product);
    let result;

      switch (type) {
        case 'auction':
       
         result = product.silent != 1 ?  participants.length + ` Entrants` : `Silent`;
       
          break;

        case 'raffle':
          result = product.silent != 1 ?  participants.length + ` Entrants` : ``;
          break;

        case 'deals':
        default:
          result = `# Avail: ${product.limitQuantity}`;
      }

      return result;


  };



  getAuctionBid = () => {
    const {type} = this.props;

    //return type === 'auction' ? this.state.changerCurrentBid : null
    return this.state.changerCurrentBid;
  };

  getParticipants = (participants) =>{
 
        var forEachData = '';
          participants.forEach(d => forEachData += `${d.name}`)

          return forEachData;
  }


  render() {
    const {type, product, onProductOffer, participants} = this.props;
    
    console.log("PARTICIPANTS:", participants);
    return (
      <PageWrapper className="bid-page">
        <ContentWrapper>
          <div className="bid-page-wrapper">
            <div className="bid-page-topline">
              <BackButton />
              <CurrentBid
                text= {Utils.getProductBadge(type, product)}
              />
            </div>
            <BidCover
              link={Utils.getProductImage(type, product)}
            />
            <div className="bid-page-description">
              <BidTimer
                endTime={product.endTime}
              />
              <div className="bid-page-info">
                <div className="bid-page-header-line">
                  <BidLogo
                    link={product.sellerImage}
                  />
                  <div className="bid-page-title">
                    <div className="bid-page-title-line">
                      <h2 className="bid-card-title">{product.title}</h2>
                      <span>{this.getProductInfo(product)}</span>
                    </div>
                    <h3 className="bid-card-subtitle">{product.subTitle}</h3>
                  
                  </div>
                </div>
                <p>{product.description}
                    <br/><br/><br/><br/>
                </p>
              </div>
            </div>
            <div className={`bid-page-control`}>
              {
                type === 'auction' &&
                <BidChanger
                  currentBidValue={product.currentBidValue}
                  minBidValue={product.minBidValue}
                  intervalBidValue={product.interval}
                  onBidChange={this.onBidChange}
                />
              }
              {
                 type === 'raffle' &&
                <QuantityChanger
                  currentQuantity={0}
                  minBidValue={1}
                  intervalBidValue={1}
                  maxInventory = {999999999}
                  onBidChange={this.onBidChange}
                />
              }

              {
                 type === 'deals' &&
                <QuantityChanger
                  currentQuantity={0}
                  minBidValue={1}
                  intervalBidValue={1}
                  maxInventory = {product.limitQuantity}
                  onBidChange={this.onBidChange}
                />
              }
             
              <CustomButton 
                disabled = {this.getExpiredTime()}
                className="bid-button"
                text={this.getButtonText()}
                onClick={() => {onProductOffer(type, product.id, this.getAuctionBid())}}
              />
            </div>
            
            
            {product.silent != 1 &&
             <div className="bid-page-description">
               <div className="bid-page-info">
                  <div className="bid-page-header-line">
                      <div className="bid-page-title">
                          <div className="bid-page-title-line">
                            <h2 className="bid-card-title">{getTypePath[type] + " Entrants"}</h2> 
                          </div>
                            <h3 className="bid-card-subtitle">{""}</h3>
                      </div>
                  </div> 

                      <p>
                      {participants.map((participant,i)=>  <h5> {participant} </h5>)}
                      </p>
                      <br/><br/><br/><br/>
               </div>
              </div>
            }
             
          </div> 
        </ContentWrapper>
      </PageWrapper>
    )
  }
};


  const getTypePath = {
  'raffle': 'Raffle',
  'auction': 'Auction',
  'deals': 'Deals'
 };

export default ProductPage;