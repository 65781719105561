import React from 'react';
import {NavLink} from 'react-router-dom';
import Routes from '../../routes';

import './style.scss';

const Copyright = () => {
  return (
    <div className="copyright-wrapper">
      Copyright &copy; PowNow&emsp;All Rights Reserved&emsp;{<NavLink to={Routes.terms}>Terms And Conditions</NavLink>}&emsp;{<NavLink to={Routes.privacy}>Privacy Policy</NavLink>}
    </div>
  )
};

export default Copyright;