import React from 'react';
import {payProduct, saveStripleToken} from "../../actions";
import {connect} from 'react-redux';
import {
    injectStripe,
    PaymentRequestButtonElement
} from 'react-stripe-elements';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from '../../components/CustomButton';

class PaymentRequestForm extends React.Component {
  constructor(props) {
    super(props);

    const {amount} = this.props;
    const {saveToken, saveTokenToProduct, payProduct, type, id, quantity} = this.props;
 console.log('Props amount: ', {amount});
 console.log('Props amount 2: ', amount);
    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: (parseInt((parseFloat(amount) + parseFloat(amount * 0.05)).toFixed(2) * quantity * 100)  ),
      },
    });
 
    /*paymentRequest.on('token', ({complete, token, ...data}) => {
      console.log('Received Stripe token: ', token);
      console.log('Received customer information: ', data);
      complete('success');
    });*/

    paymentRequest.on('source', ({complete, source, ...data}) => {
      console.log('Received Stripe source: ', source);
      console.log('Received customer information: ', data);

           toast("Processing...", {
            // Set to 5sec
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            progressStyle: { background: "#26ffc7"},
          });
      complete('success');
      payProduct(parseInt((parseFloat(amount) + parseFloat(amount * 0.05)).toFixed(2) * quantity * 100), source, type, false, false, id, quantity);
      saveTokenToProduct(source);
    });
 
    paymentRequest.canMakePayment().then((result) => {
      this.setState({canMakePayment: !!result});
    });
 
    this.state = {
      canMakePayment: false,
      paymentRequest,
    };
  }
 
  render() {
    return this.state.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        className="PaymentRequestButton"
        style={{
          // For more details on how to style the Payment Request Button, see:
          // https://stripe.com/docs/elements/payment-request-button#styling-the-element
          paymentRequestButton: {
            theme: 'dark',
            height: '40px',
          },
        }}
      />
    ) : null;

    
  }
}


const mapStateToProps = ({login: {stripleToken}, products: {productOffer}}) => {
  return {
    stripleToken,
    productOffer
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveStripleToken: (...props) => {dispatch(saveStripleToken(...props))},
    payProduct: (...props) => {dispatch(payProduct(...props))}
  }
};

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(PaymentRequestForm));