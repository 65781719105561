import React from 'react';
import history from '../../../history';
import Routes from '../../../routes';
import moment from 'moment/moment.js';
import countdown from 'moment-countdown';

class BidTimer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: '00:00:00'
    };
  }

  getCountdown = () => {
    const {endTime} = this.props;
    const then = moment(endTime, 'YYYY-MM-DD HH:mm:ss');
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    const countdownMS = then.diff(now); //moment(now - then); 
   
  //let timer1 = this.msToTime(countdownMS);//moment(endTime).countdown(); //moment(countdown).format('YYYY-MM-DD HH:mm:ss');//countdown().moment(endTime);  //countdown.toString; //moment("1982-5-25").countdown.countdown().toString();//countdown();//countdown(moment(),moment(endTime));//moment().diff(moment(endTime));//moment(endTime).countdown();
  //console.log("Moment CurrTime:", now);
  //console.log("Moment Then:", then);
   
    if (this.getExpiredTime()){ 
      this.timerEnd();
      return '00:00:00' 
    } else {
      return  `${this.msToTime(countdownMS)}`;//`${this.getValidStr((timer1.days * 24) + timer1.hours)}:${this.getValidStr(timer1.minutes)}:${this.getValidStr(timer1.seconds)}`;
    }

  };

  getValidStr = (string) =>{

    return `${string}`.length === 1 ? `0${string}` : string;
  };

  msToTime = (duration) => {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60))); //% 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;// + "." + milliseconds;
};

getExpiredTime = () => {
    const {endTime} = this.props;
    //const {product} = this.props;
    const endTimeMoment = moment(endTime, 'YYYY-MM-DD HH:mm:ss');
    const currMoment = moment().format('YYYY-MM-DD HH:mm:ss');
 
    //console.log("PRODUCT ENDTIME:",endTime);
    //console.log("Moment CurrTime:",currMoment);
    let result = endTimeMoment.isAfter(moment(currMoment ,'YYYY-MM-DD HH:mm:ss')) ? false : true

    return result;
  };

  timerEnd = () => {
    const {fetchList} = this.props;

    clearInterval(this.interval);

    if (history.location.pathname === Routes.main) {
      fetchList();
      //history.push(Routes.main);
    } else {
      history.push(Routes.main);
    }
  };

  componentDidMount() {

    this.setState({
      timer: this.getCountdown()
    });

    this.interval = setInterval(() => {
      const timer = this.getCountdown();

      this.setState({
        timer
      });

      if (timer === '00:00:00') {
        this.timerEnd();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { timer } = this.state;

    return (
      <div className="bid-card-timer">
        {timer}
      </div>
    )
  }

    
}

export default BidTimer;