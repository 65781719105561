export const login = '/';
export const signIn = '/signIn';
export const signUp = '/signUp';
export const main = '/main';
export const verifyCode = '/verifyCode';
export const bidPage = '/bid';
export const productPage = '/product';
export const productReceivedPage = '/productReceived';
export const bidReceived = '/bidReceived';
export const settings = '/setting';
export const help = '/help';
export const purchases = '/purchases';
export const card = '/card';
export const stripleCheckout = '/striple';
export const terms = "/terms";
export const privacy = "/privacy";

export default {
  login,
  signIn,
  signUp,
  main,
  verifyCode,
  bidPage,
  productPage,
  bidReceived,
  settings,
  help,
  purchases,
  card,
  stripleCheckout,
  productReceivedPage,
  terms,
  privacy
}