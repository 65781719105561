import login from './login';
import navBar from './navBar';
import products from './products'
import {reducer as formReducer} from 'redux-form';

export default {
  login,
  navBar,
  products,
  form: formReducer
}