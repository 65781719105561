import React from 'react';

import './style.scss';

const StripleSavedCard = ({type, name, card, setNewCard}) => {
  return (
    <div
      onClick={(e) => {if (setNewCard) setNewCard()}}
      className={`striple-saved-card ${type !== 'card' ? 'button' : ''}`}
    >
      <span className="card-name">{name}</span>
      {
        type === "card"&&<span className="card-number">{card}</span>
      }
    </div>
  )
};

export default StripleSavedCard;