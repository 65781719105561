import React from 'react';
import { withRouter } from 'react-router-dom';
import Routes from "../../routes";
import {BidLogo, CurrentBid, BidTimer} from "../BidComponents";

import './style.css';

class BidInlineCard extends React.Component {

    constructor(props) {
    super(props);

    this.state = {
      badge : ' ',
      info : ' ',
    };
  } 

   componentDidMount() {
    const {badge, info} = this.props;
    this.setState({
      badge: badge,
      info: info,
    });

  }

  onBidClick = () => {
    const {id, type, history} = this.props;

    history.push(`${Routes.productReceivedPage}/${'buy'}/${type}/${id}`);
  };

  render() {
    const {title, badge, endTime, productImage, fetchList} = this.props;

    return (
      <div
        className="bid-inline-card"
        onClick={this.onBidClick}
      >
        <div className="bid-inline-info">
          <BidLogo
            link={productImage}
          />

          <div className="bid-card-header">
            <h2 className="bid-card-title">{title}</h2>
            <div className="bid-card-header-second-line">
              <CurrentBid
                text={title}
              />
              <BidTimer
                endTime={endTime}
                fetchList={fetchList}
              />
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default withRouter(BidInlineCard);