export const required = value => !value;
export const number = value => value && isNaN(Number(value)) ? true : undefined;
export const length16 = value => value && value.length === 16 ? undefined : true;
export const length3 = value => value && value.length === 3 ? undefined : true;
export const expireDate = value => value && /^\d{2}\/\d{2}$/.test(value) ? undefined : true;

export default {
  required,
  number,
  length16,
  length3,
  expireDate
}