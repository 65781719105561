import validator from './validator';
import moment from 'moment';
//import countdown from 'moment-countdown';

const getPurchaseCountdown = (purchaseData, max) => {
  //let timer = moment(purchaseData) - moment(); //.countdown(purchaseData);// moment().countdown(purchaseData);


    const then = moment(purchaseData, 'YYYY-MM-DD HH:mm:ss');
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    const countdownMS = then.diff(now);



  //console.log("Timer: ", timer);
  let mins = Math.abs(countdownMS) / (60 * 1000);

  if (mins > max) {
    return 0;
  }

  return max - mins;
};

const iterationCopy = (src) => {
  let target = {};
  for (let prop in src) {
    if (src.hasOwnProperty(prop)) {
      target[prop] = src[prop];
    }
  }
  return target;
};


const getRightProducts = (products) => {
  return products.filter(elem => {
    return  moment() > moment(elem.startTime) && moment() < moment(elem.endTime)
  })
};

export {
  getPurchaseCountdown,
  iterationCopy,
  validator,
  getRightProducts
}