import React from 'react';
import PageWrapper from '../../components/PageWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import PurchasesCard from '../../components/PurchasesCard';
import {NavLink} from 'react-router-dom';
import Routes from '../../routes';

import './style.scss';

class Purchases extends React.Component {
  getTypePurchase = (type = 'auction') => {
    const {purchases} = this.props;

    return purchases.filter(elem => {
      const product = this.props[elem.type].filter(prod => prod.id === elem.id)[0];

      return elem.type === type && !!product;
    });
  };

  render() {

    const {raffle, deals, auction, fetchPurchasesList} = this.props;

    const rafflePurchases = this.getTypePurchase('raffle');
    const dealsPurchases = this.getTypePurchase('deals');
    const auctionPurchases = this.getTypePurchase('auction');

    return (
      <PageWrapper className="purchases-page">
        <ContentWrapper>
          <div className="purchases-page-content">
            <PageHeader
              header='Active Purchases'
            />
            {
              rafflePurchases.length
                ?
                  <section className="purchases-page-section">
                    <h2>Raffles</h2>
                    {
                      rafflePurchases.map(elem => {
                        const product = raffle.filter(prod => prod.id === elem.id)[0];

                        return (
                          <NavLink
                            key={elem.type + elem.id}
                            to={`${Routes.productReceivedPage}/${'purchase'}/${elem.type}/${elem.id}`}
                          >
                            <PurchasesCard
                              fetchPurchasesList={fetchPurchasesList}
                              type={elem.type}
                              count={elem.quantity}
                              title={product.title}
                              purchaseData ={elem.purchaseTime}
                              image={product.raffleImage}
                            />
                          </NavLink>
                        )
                        }
                      )
                    }
                  </section>
                : null
            }
            {
              auctionPurchases.length
                ?
                  <section className="purchases-page-section">
                    <h2>Auction</h2>
                    {
                      auctionPurchases.map(elem => {
                        const product = auction.filter(prod => prod.id === elem.id)[0];

                        return (
                          <NavLink
                            key={elem.type + elem.id}
                            to={`${Routes.productReceivedPage}/${'purchase'}/${elem.type}/${elem.id}`}
                          >
                            <PurchasesCard
                              fetchPurchasesList={fetchPurchasesList}
                              type={elem.type}
                              count={elem.bidAmount}
                              title={product.title}
                              purchaseData ={elem.purchaseTime}
                              image={product.bidImage}
                            />
                          </NavLink>
                        )
                        }
                      )
                    }
                  </section>
                : null
            }
            {
              dealsPurchases.length
                ?
                  <section className="purchases-page-section">
                    <h2>Deals</h2>
                    {
                      dealsPurchases.map(elem => {
                          const product = deals.filter(prod => prod.id === elem.id)[0];

                          return (
                            <NavLink
                              key={elem.type + elem.id}
                              to={`${Routes.productReceivedPage}/${'purchase'}/${elem.type}/${elem.id}`}
                            >
                              <PurchasesCard
                                fetchPurchasesList={fetchPurchasesList}
                                type={elem.type}
                                count={elem.quantity}
                                title={product.title}
                                purchaseData ={elem.purchaseTime}
                                image={product.dealImage}
                              />
                            </NavLink>
                          )
                        }
                      )
                    }
                  </section>
                : null
            }
          </div>
        </ContentWrapper>
      </PageWrapper>
    )
  }
}

export default Purchases;