import React from 'react';
import { withRouter } from 'react-router-dom';

import './style.css';

class BackButton extends React.Component {
  render() {
    const {history} = this.props;

    return (
      <div className="back-button"
        onClick={() => {history.goBack()}}
      >
        <i className="fas fa-arrow-left"></i>
      </div>
    )
  }
}

export default withRouter(BackButton);