import React from 'react';
import PageWrapper from '../PageWrapper';
import ContentWrapper from '../ContentWrapper';

import './style.scss';

const Info = ({text}) => {
  return (
    <PageWrapper className="info-wrapper">
      <ContentWrapper>
        <p>{text}</p>
      </ContentWrapper>
    </PageWrapper>
  )
};

export default Info;