import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import Routes from '../../routes';
import ProductPage from "./ProductPage";
import {
  fetchAuctionList, fetchDealsList, fetchRaffleList, setAuctionOffer, setDealsOffer,
  setRaffleOffer
} from '../../actions/products';
import {fetchParticipantsList} from '../../actions/products';


class ProductPageContainer extends Component {
/*  setNewBid = (id, bid) => {
    const {setNewCurrentBid, history} = this.props;

    setNewCurrentBid(id, bid);

    history.push(Routes.bidReceived);
  };*/

  onProductOffer = (type, ...props) => {
    const {setAuctionOffer, setDealsOffer, setRaffleOffer} = this.props;

    //setProductOffer(type, ...props);
console.log("ONPRODUCT OFFER");
    switch (type) {
      case 'auction':
        setAuctionOffer(...props);
        break;

      case 'deals':
        setDealsOffer(...props);
        break;

      case 'raffle':
        setRaffleOffer(...props);
        break;
    }

    //history.push(Routes.bidReceived);
  };

  startAuctionUpdating = () => {
    const {fetchAuctionList} = this.props;

    if(!this.intervalId){
    this.intervalId  = setInterval(() => {
             console.log('startAuctionUpdating Interval:',this.intervalId);
             fetchAuctionList();

      }, 5000);
    }

  };

componentDidMount() {
     
    const { auction, raffle, deals, match:{params}} = this.props;
    const {type, id} = params;
    const {fetchParticipantsList} = this.props;

    if (this.intervalId) {
      clearInterval(this.intervalId)
    }

    if (type === 'auction'){
      this.startAuctionUpdating();

    }
    console.log("FETCHING PARTICIPANT LIST HERE");
    fetchParticipantsList(type,id);
  

  }



  render() {
    const { auction, raffle, deals, participants, match:{params}} = this.props;
    const {type, id} = params;
    let products;
    let participantss = participants;
      console.log("PARTICIPANTS IN PRODUCT PAGE INTIAL:", participantss);

    switch (type) {
      case 'raffle':
        products = raffle;
        break;

      case 'deals':
        products = deals;
        break;

      case 'auction':
      default:
        products = auction;
    }

    if (!products.length) {
      return (
        <Redirect to={Routes.main} />
      )
    }

    const product = products.filter(elem => {
      return elem.id === id
    });

    if (!product.length) {
      return (
        <Redirect to={Routes.main} />
      )
    }

    return (
      <ProductPage
        type={type}
        product={product[0]}
        onProductOffer={this.onProductOffer}
        onAuction = {this.startAuctionUpdating}
        participants = {participants}
      />
    );
  }

 componentWillUnmount() {

  //const {intervalId} = this.props;
  console.log("WILL UNMOUNT PRODUCT:", this.intervalId);
   clearInterval(this.intervalId);
  }


}



const mapStateToProps = ({products}) => {
  return {
    auction: products.auction,
    raffle: products.raffle,
    deals: products.deals,
    participants: products.participants,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAuctionList: () => {dispatch(fetchAuctionList())},
    fetchDealsList: () => {dispatch(fetchDealsList())},
    fetchRaffleList: () => {dispatch(fetchRaffleList())},
    fetchParticipantsList: (...props) => {dispatch(fetchParticipantsList(...props))},
    //setProductOffer: (...props) => {dispatch(setProductOffer(...props))},
    setAuctionOffer: (...props) => {dispatch(setAuctionOffer(...props))},
    setDealsOffer: (...props) => {dispatch(setDealsOffer(...props))},
    setRaffleOffer: (...props) => {dispatch(setRaffleOffer(...props))},
   //startAuctionUpdating: (...props) => {dispatch(startAuctionUpdating(...props))}
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer));