import React from 'react';
import SideBar from '../SideBar';
import {NavLink} from 'react-router-dom';
import Routes from '../../routes';
import { ReactComponent as PurchasesIcon } from '../../assets/purchasesIcon.svg';

import './style.scss';

class MenuBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  onMenuClick = () => {
    const {isOpen} = this.state;

    this.setState({
      isOpen: !isOpen
    })
  };

  render() {
    const {isOpen} = this.state;

    return (
      <div
        className={`menu-bar ${isOpen ? 'menu-open' : ''}`}
      >
        <div
          className="menu-bar-burger"
          onClick={this.onMenuClick}
        >
          <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`} />
        </div>
        <NavLink
          to={Routes.purchases}
          className="menu-bar-purchases"
        >
          <PurchasesIcon />
        </NavLink>
        <SideBar
          isOpen={isOpen}
          closeMenu={this.onMenuClick}
        />
      </div>
    )
  }
}

export default MenuBar;