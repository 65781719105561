import React from 'react';
import {connect} from 'react-redux';
import {fetchSignOut} from "../../actions";
import SideBar from './SideBar';

class SideBarContainer extends React.Component {
  handleSignOut = (event) => {
    const {handleSignOut} = this.props;

    event.preventDefault();

    handleSignOut();
  };

  render() {
    const {isOpen, closeMenu} = this.props;

    return (
      <SideBar
        handleSignOut={this.handleSignOut}
        isOpen={isOpen}
        closeMenu={closeMenu}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSignOut: () => {dispatch(fetchSignOut())}
  }
};

export default connect(null, mapDispatchToProps)(SideBarContainer);