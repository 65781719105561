import React from 'react';
import BidReceivedModal from '../../components/BidReceivedModal';
import BidReceivedResult from '../../components/BidReceivedModal/BidReceivedResult';


import './style.scss';

class BidReceived extends React.Component {

  render() {
    const { products, productOffer , modalClose, modalBidAgain } = this.props;

  const product = productOffer ? products.filter(elem => elem.id === productOffer.id)[0] : null;
  console.log("BIDRECEIVEDPAGE");
  console.log("PRODUCTSTATE", productOffer.type);
  console.log("PRODUCT OFFER ID", productOffer.id);
 console.log("BIDRECEIVEDPAGE1 productOffer:",productOffer);
      console.log("BIDRECEIVEDPAGE1 product:",product);

  /*  if (product.state) {
      productOffer.bidMax = productOffer.bid;

      return (
        <div>
          <BidReceivedResult
            receivedType={product.state}
            productOffer={productOffer}
            product={product}
            modalClose={modalClose}
            modalBidAgain={modalBidAgain}
            
          />
        </div>
      )
    }*/ //Will took out Nov 29th because product state is always empty and unset 

    if(productOffer.type === "auction"){
      productOffer.bidMax = productOffer.bid;
    }

    return (
      <div>
        <BidReceivedModal
          productOffer={productOffer}
          product={product}
          modalClose={modalClose}
          modalBidAgain={modalBidAgain}
        />
      </div>
    )
  }
}

export default BidReceived;