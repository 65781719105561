import React from 'react';

import './style.scss';

const CustomCheckbox = ({value, placeholder, meta = {error: false, touched: false}, input}) => {
  return (
    <div className={`custom-checkbox ${meta.error && meta.touched ? 'input-error' : ''}`}>
      <label className="checkbox-container">
        <input type="checkbox" {...input} checked={value} />
        <span className="checkmark" />
      </label>
      <span className="checkbox-placeholder">{placeholder}</span>
    </div>
  )
};

export default CustomCheckbox;