import React from 'react';

const CurrentBid = ({text}) => {
  return (
    <div className="bid-card-bid">
      {text}
    </div>
  )
};

export default CurrentBid;