import React from 'react';
import {connect} from 'react-redux';
import {setActiveElement} from "../../actions/navBar";

import './style.scss';

class NavBar extends React.Component {
  render() {
    const {barElements, setActiveElement} = this.props;

    return (
      <div className="nav-bar">
        {
          barElements.map(elem => {
            return (
              <span
                key={elem.name}
                className={elem.active ? 'active' : ''}
                onClick={() => {setActiveElement(elem.name)}}
              >
                {elem.name}
              </span>
            )
          })
        }
      </div>
    )
  }
}

const mapStateToProps = ({navBar}) => {
  return {
    barElements: navBar.elements
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveElement: (...props) => {dispatch(setActiveElement(...props))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);