import React from 'react';
import Routes from '../../routes';
import { NavLink } from 'react-router-dom';

import './style.scss';

const pages = [
  {
    name: 'Main',
    link: Routes.main
  },
 /* {
    name: 'Settings',
    link: Routes.settings
  },*/
  {
    name: 'Help',
    link: Routes.help
  }
];

class SideBar extends React.Component {
  onSideClick = (event) => {
    const {closeMenu} = this.props;

    if (event.target.tagName === 'A') {
      closeMenu();
    }
  };

  render() {
    const {isOpen, handleSignOut} = this.props;

    return (
      <div className={`side-bar ${isOpen ? 'side-bar-open' : ''}`}>
        <div
          className="side-bar-content"
          onClick={this.onSideClick}
        >
          {
            pages.map(elem => {
              return (
                <NavLink
                  key={elem.name}
                  to={elem.link}
                  isActive={(match, location) => {
                    if (elem.name === 'Main' && location.pathname.includes('/main'))
                      return true;

                    return !!match;
                  }}
                >
                  {elem.name}
                </NavLink>
              )
            })
          }
          <a
            href="#"
            className="sign-out-button"
            onClick={handleSignOut}
          >
            Sign Out
          </a>
        </div>
      </div>
    )
  }
}

export default SideBar;