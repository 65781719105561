import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import ProductReceived from "./ProductReceived";
import Routes from '../../routes';
import {fetchPurchasesList, chargeAuctionWinPurchases} from "../../actions/products";
import BidReceivedModal from '../../components/BidReceivedModal';

import app from "../../base";

class HomeContainer extends Component {
  modalClose = () => {
    const {history} = this.props;

    history.push(Routes.main);
  };

  modalBidAgain = () => {
    console.log("ProductRECEIVEDPAGE");
    const {history, match: {params}} = this.props;
    const {type, id} = params;

    history.push(`${Routes.productPage}/${type}/${id}`);
  };

  render() {
    const { auction, raffle, deals, purchases, match:{params} } = this.props;
    const {pageType, type, id} = params;
    const {chargeAuctionWinPurchases} = this.props;

    let products;

    switch (type) {
      case 'auction':
        products = auction;
        break;
      case 'raffle':
        products = raffle;
        break;
      case 'deals':
        products = deals;
        break;
      default: {
        products = [];
      }
    }

    if (!products.length) {
      return (
        <Redirect to={Routes.main} />
      )
    }

    const product = products.filter(elem => {
      return elem.id === id
    })[0];

    const purchase = purchases.filter(elem => {
       return elem.type === type && elem.id === id;
    })[0];
    

    if (pageType === 'purchase') {
      if (!purchase) {
        return (
          <Redirect to={`${Routes.productPage}/${type}/${id}`} />
        )
      } else if (!purchase.state || purchase.state === "charged"){
          const productOffer = {
            type,
            id,
            bid: purchase.type !== 'auction' ? purchase.quantity : purchase.bidAmount,
            bidMax: purchase.type === 'auction' && purchase.bidMax ? purchase.bidMax: purchase.bidAmount,
            phoneNumber: app.auth().currentUser.phoneNumber
          };

          return (
            <BidReceivedModal
              productOffer={productOffer}
              product={product}
              modalClose={this.modalClose}
              modalBidAgain={this.modalBidAgain}
              chargeAuctionWinPurchases={chargeAuctionWinPurchases}
            />
          )
      }
    } else {
      if (!purchase || !purchase.state) {
        return (
          <Redirect to={`${Routes.productPage}/${type}/${id}`} />
        )
      }
    }

    const productOffer = {
      type,
      id,
      bid: purchase.type !== 'auction' ? purchase.quantity : purchase.bidAmount,
      bidMax: purchase.type === 'auction' && purchase.bidMax ? purchase.bidMax: purchase.bidAmount,
      phoneNumber: app.auth().currentUser.phoneNumber
    };

    if (purchase.state) {
      product.state = purchase.state;
    }

    return (
      <ProductReceived
        product={product}
        productOffer={productOffer}
        modalClose={this.modalClose}
      />
    )
  }
}

const mapStateToProps = ({products}) => {
  return {
    auction: products.auction,
    raffle: products.raffle,
    deals: products.deals,
    purchases: products.purchases
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPurchasesList: () => {dispatch(fetchPurchasesList())}, 
    chargeAuctionWinPurchases: (...props) => {dispatch(chargeAuctionWinPurchases(...props))}
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));