import React from 'react';
import StripleSavedCard from '../StripleSavedCard';

import './style.scss';

const StripleNewButton = ({setNewCard}) => {
  return (
    <div className="striple-new">
      <StripleSavedCard
        name="New card"
        setNewCard={setNewCard}
      />
    </div>
  )
};

export default StripleNewButton;