import React from 'react';

import './style.css';

class BidChanger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changerCurrentBid: 0,
      changerMinBid: 0
    }
  }

  setBidValues = (currentBidValue, minBidValue) => {
    const {onBidChange} = this.props;

    const newChangerBid = parseInt(currentBidValue) + parseInt(minBidValue);

    this.setState({
      changerCurrentBid: newChangerBid,
      changerMinBid: newChangerBid,
      interval: parseInt(minBidValue)
    }, () => {
      onBidChange(newChangerBid);
    });
  };

  setCurrentBid = (bid) => {
    const {onBidChange} = this.props;

    this.setState({
      changerCurrentBid: bid
    }, () => {
      onBidChange(bid);
    });
  };

  componentDidMount() {
    const {currentBidValue, minBidValue, intervalBidValue} = this.props;

    this.setBidValues(currentBidValue, minBidValue, intervalBidValue);
  }

  componentWillReceiveProps(nextProps) {
    const {currentBidValue, minBidValue, intervalBidValue} = this.props;

    if (currentBidValue !== nextProps.currentBidValue || minBidValue !== nextProps.minBidValue || intervalBidValue !== nextProps.intervalBidValue) {
      this.setBidValues(nextProps.currentBidValue, nextProps.minBidValue, nextProps.intervalBidValue);
    }
  }

  onBidClick = (approach) => {
    const {changerCurrentBid, changerMinBid, interval} = this.state;
    let result = changerMinBid;

    if (approach === 'plus') {
      result = changerCurrentBid + interval;
    } else if (approach === 'minus') {
      if (changerCurrentBid - interval >= changerMinBid) {
        result = changerCurrentBid - interval;
      }
    }

    if (changerCurrentBid !== result) {
      this.setCurrentBid(result)
    }
  };

  render() {
    const {changerCurrentBid, changerMinBid} = this.state;

    return (
      <div className="bid-changer-wrapper">
        <div
          className={`bid-changer-control ${changerCurrentBid <= changerMinBid ? 'deactivated-control' : ''}`}
          onClick={() => {this.onBidClick('minus')}}
        >
          <i className="fas fa-minus" />
        </div>
        <div className="bid-changer-display">
          <h4>total bid</h4>
          <span>{`$${changerCurrentBid}`}</span>
        </div>
        <div
          className="bid-changer-control"
          onClick={() => {this.onBidClick('plus')}}
        >
          <i className="fas fa-plus" />
        </div>
      </div>
    )
  }
}

export default BidChanger;