import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ProductCard from '../../components/ProductCard';
import ProductInlineCard from '../../components/ProductInlineCard';
import {Utils} from '../../components/BidComponents';

import './style.scss';

class Main extends React.Component {
  render() {
    const { products, type, fetchList } = this.props;

    return (
      <div className="product-page">
        <div className="bid-inline-wrapper">
          {
            products.filter(elem => elem.featureFlag === "1").map((elem) => {
              return (
                <ProductInlineCard
                  key={elem.id}
                  type={type}
                  id={elem.id}
                  title={elem.title}
                  badge={Utils.getProductBadge(type, elem)}
                  endTime={elem.endTime}
                  productImage={Utils.getProductImage(type, elem)}
                  winner= {elem.winner}
                  fetchList={fetchList}
                />
              )
            })
          }
        </div>
        <Grid fluid className="bid-card-wrapper">
          <Row center="xs">
            {
              products.filter(elem => elem.featureFlag !== "1").map((elem) => {
                return (
                  <Col className='custom-col' sm={12} md={6} xl={4} key={elem.id}>
                    <ProductCard
                      key={elem.id}
                      type={type}
                      id={elem.id}
                      title={elem.title}
                      subTitle={elem.subTitle}
                      badge={Utils.getProductBadge(type, elem)}
                      info={Utils.getProductInfo(type, elem)}
                      endTime={elem.endTime}
                      sellerImage={elem.sellerImage}
                      productImage={Utils.getProductImage(type, elem)}
                      winner = {elem.winner}
                      fetchList={fetchList}
                    />
                  </Col>
                )
              })
            }
          </Row>
        </Grid>
      </div>
    )
  }
}

export default Main;