import React from 'react';
import { withRouter } from 'react-router-dom';
import Routes from '../../routes';
import {BidLogo, BidCover, CurrentBid, BidTimer} from "../BidComponents";
import moment from 'moment/moment.js'
import countdown from 'moment-countdown';

import './style.css';

class BidCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      badge : ' ',
      info : ' ',
    };
  } 

   componentDidMount() {
    const {badge, info} = this.props;
    this.setState({
      badge: badge,
      info: info,
    });

  }

  onBidClick = () => {
    const {id, type, history} = this.props;

    history.push(`${Routes.productReceivedPage}/${'buy'}/${type}/${id}`);
  };

   /* updateState = () => {
       const {title, subTitle, badge, info, endTime, sellerImage, productImage, fetchList} = this.props;
        // Changing state
        this.setState({
            badge: badge,
            info: info,
        });
    }*/

  render() {
    const {title, subTitle, badge, info, endTime, sellerImage, productImage, fetchList} = this.props;
 

    return (

     
      <div
        className="bid-card"
        onClick={this.onBidClick}
      >
        <BidCover
          link={productImage}
        />
        <div className="bid-card-content">
          <div className="bid-card-info">
            <BidLogo
              link={sellerImage}
            />
            <CurrentBid
              text={badge}
            />
          </div>
          <div className="bid-card-description">
            <div className="bid-card-header">
              <h2 className="bid-card-title">{title}</h2>
              <span>{info}</span>
            </div>
            <div className="bid-card-subHeader">
              <h3 className="bid-card-subtitle">
                {subTitle}
              </h3>
            </div>
            <BidTimer
              endTime={endTime}
              fetchList={fetchList}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(BidCard);