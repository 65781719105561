import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import BidReceived from "./BidReceived";
import {setNullOffer, chargeAuctionWinPurchases} from "../../actions/products";
import Routes from '../../routes';

class HomeContainer extends Component {
  modalClose = () => {
    const {setNullOffer} = this.props;

    setNullOffer();
  };

  modalBidAgain = () => {
    const {productOffer, history} = this.props;

    this.modalClose();

    history.push(`${Routes.productPage}/${productOffer.type}/${productOffer.id}`);
  };

  render() {
    const { productOffer, auction, raffle, deals, purchases } = this.props;
    let products;
     
    if (!productOffer || !productOffer.id) {
      return (<Redirect to={Routes.main} />)
    }

    switch (productOffer.type) {
      case 'raffle':
        products = raffle;
        break;

      case 'deals':
        products = deals;
        break;

      case 'auction':
      default:
        products = auction;
    }
 console.log("BIDRECEIVEDPAGE productOffer:",productOffer);
      console.log("BIDRECEIVEDPAGE product:",products);
    return (
      <BidReceived
        purchases={purchases}
        products={products}
        productOffer={productOffer}
        modalClose={this.modalClose}
        modalBidAgain={this.modalBidAgain}
      />
    );
  }
}

const mapStateToProps = ({products}) => {
  return {
    auction: products.auction,
    raffle: products.raffle,
    deals: products.deals,
    productOffer: products.productOffer,
    purchases: products.purchases
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNullOffer: () => {dispatch(setNullOffer())}
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));