import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './index.scss';
import App from './App';
import store from './configureStore';
import history from './history';
import moment from 'moment';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

window.moment = moment; //TODO: delete
console.log(process.env.REACT_APP_KEY)

ReactDOM.render(
  <Router forceRefresh={true} history={history}>
    <Provider store={store}>
     <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark" />
      <App />
    </Provider>
  </Router>
  ,
document.getElementById('root'));